import { Button, Checkbox, Divider, Input, List, message, Modal, Radio, Result } from "antd";
import { Loader } from "components/Loader";
import { displayCADMoney } from "helpers/utils";
import { useEffect, useState } from "react";
import StoreService from "services/store.service";
import UserService from "services/user.service";
const storeService = new StoreService();
const userService = new UserService();

const PayoutModal = ({ isVisible, storeId, driverId, available, onClose }) => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [confirmWord, setConfirmWord] = useState('');
    const [amount, setAmount] = useState('');

    useEffect(() => {
        setSuccess(false);
        setAmount('');
        setConfirmWord('');
    }, [])

    const voidOrder = async () => {
        try {
            setLoading(true);
            if (storeId) {
                await storeService.payToStore(storeId, amount);
            } else if (driverId) {
                await userService.payToDriver(driverId, amount)
            }
            setSuccess(true);
            setLoading(false);
        } catch (e) {
            console.log(e)
            setLoading(false);
            message.error(e.response ? e.response.data.error.message : "Something was wrong paying the money, please try again in a while")
        }
    }

    return (
        <Modal title={`Payout money`} onCancel={onClose} footer={null} visible={isVisible}>
            {loading && <Loader text="Paying..." />}
            {
                success ? (
                    <Result
                        status="success"
                        title="Successfully paid!"
                        subTitle="Please check the records on the payouts tab"
                    />
                ) : (
                    <>
                        <h3>Available: {displayCADMoney(available)}</h3>
                        <Input min={0} value={amount} type="number" onChange={e => setAmount(e.target.value)} placeholder="Amount to be paid" />
                        <Divider orientation="left"></Divider>
                        <Input value={confirmWord} onChange={e => setConfirmWord(e.target.value)} placeholder="Please type confirm to complete the payout" />
                        <br />
                        <br />
                        <Button onClick={voidOrder} disabled={!amount || confirmWord !== "confirm"} type="primary">Pay money</Button>
                    </>
                )
            }
        </Modal>
    )
}

export default PayoutModal;
import { Loader } from "components/Loader";
import { Form, Input, Button, message } from 'antd';
import OrderService from "services/order.service";
import { useState } from "react";
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import OrdersTable from "views/Orders/OrdersTable";
import { CustomDrawer } from "views/Orders/styled";
import OrderInformation from "views/Orders/OrderInformation";
const orderService = new OrderService();

const SearchOrder = () => {
    const textColor = useColorModeValue("gray.700", "white");
    const [order, setOrder] = useState({});
    const [_orderNo, setOrderNo] = useState({});
    const [loading, setLoading] = useState(false);
    const onOrderOpened = order => {
        setOrder(order);
    }

    const onClose = () => {
        setOrder({});
    };

    const getOrderByOrderNo = async orderNo => {
        try {
            setLoading(true);
            setOrderNo(orderNo);
            const _order = await orderService.getOneByOrderNo(orderNo);
            setOrder(_order);
            setLoading(false);
        } catch (e) {
            message.error("Order not found")
            setLoading(false);
        }
    }
    const onFinish = async ({ orderNo }) => {
        getOrderByOrderNo(orderNo);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
            {loading && <Loader />}
            <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
                <CardHeader p="6px 0px 22px 0px">
                    <Flex justifyContent="space-between" width="100%">
                        <Text fontSize="xl" color={textColor} fontWeight="bold">
                            Search an order using its number
                        </Text>
                    </Flex>
                </CardHeader>
                <CardBody>
                    <div>
                        <Form
                            name="basic"
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            layout="inline"
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item
                                label="Order Number"
                                name="orderNo"
                                rules={[{ required: true, message: 'Please input the order number' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item >
                                <Button type="primary" htmlType="submit">
                                    Search
                                </Button>
                            </Form.Item>
                        </Form>
                        <br />
                        <br />
                        <br />
                        {order.id && <OrdersTable onOrderOpened={onOrderOpened} orders={[order]} />}

                        <CustomDrawer
                            title={`Order #${(order || {}).orderNo}`}
                            width={520}
                            placement="right"
                            onClose={onClose}
                            visible={order.id !== undefined}
                        >
                            <OrderInformation onOrderUpdated={() => getOrderByOrderNo(_orderNo)} order={order} />
                        </CustomDrawer>
                    </div>
                </CardBody>
            </Card>

        </Flex>
    );
}

export default SearchOrder;
import { Button, Popconfirm, Empty, message, Select, Card } from 'antd';
import { useState, useEffect, useCallback, useContext } from 'react';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import StoreService from '../../services/store.service';
import AddNewProductForm from './AddNewProductForm';
import AddNewCategoryForm from './AddNewCategory';
import ProductService from '../../services/product.service';
import { Loader } from '../../components/Loader';
import ProductsTable from './ProductsTable';
import BulkProductsUpload from './BulkProductsUpload';
import { ButtonsContainer, CustomDrawer, Container } from './styled';
import { AppContext } from 'AppContext';
const  { Option } = Select;
const storeService = new StoreService();
const productService = new ProductService();


export default function Products() {
  const { store } = useContext(AppContext);
  const [loading, setLoading] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [product, setProduct] = useState(null)
  const [visible, setVisible] = useState(false);
  const [addingCategory, setAddingCategory] = useState(false);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
	const [isBulkModalVisible, setIsBulkModalVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
    setAddingCategory(false);
    setProduct(null);
  };

  const getStore = useCallback(async () => {
    if (store.id) {
      setLoading('Fetching products');
      const _store = await storeService.getOne(store.id);
      setCategories(_store.categories);
      setProducts(_store.products.map((p) => ({ ...p, key: p.id })));
      setLoading(null);
    }
  }, [store.id])

  const addNewCategory = () => {
    setAddingCategory(true);
    showDrawer();
  }

  const editProduct = (product) => {
    setProduct(product);
    setTimeout(showDrawer, 200);
  }

  const addProduct = () => {
    setProduct(null);
    setTimeout(showDrawer, 500);
  }

  const fetchProducts = useCallback(async () => {
    setVisible(false);
    setAddingCategory(false);
    if (selectedCategory === 'all')
      return getStore();
    setLoading(`Fetching products for ${selectedCategory.name}`);
    const _category = await productService.getCategory(selectedCategory.id);
    setProducts(_category.products);
    setLoading(null);
  }, [selectedCategory, setProducts,getStore, setLoading])

  useEffect(() => {
    fetchProducts();
  }, [store, selectedCategory, fetchProducts]);

  const updateSelectedCategory = (categoryId) => {
    if (categoryId === 'all') {
      return setSelectedCategory('all');
    }
    const category = categories.find(c => c.id === categoryId);
    setSelectedCategory(category);
  }

  const updateCategories = async () => {
    const { categories: newCategories } = await storeService.getOne(store.id);
    setCategories(newCategories);
    return newCategories;
  }

  const onCreatedCategory = async (newCategory) => {
    if (!newCategory) {
      const newCategories = await updateCategories();
      setSelectedCategory(newCategories.find((c) => c.id === selectedCategory.id))
    }

    setTimeout(fetchProducts, 200);
  }

  const onDeleteProduct = product => {
    setLoading('Deleting product');
    deleteProduct(product.id);
    setTimeout(fetchProducts, 200);
  }

  const onCompleteBulkUpload = async (result) => {
    setIsBulkModalVisible(false);
    if (result === "completed") {
      await getStore();
      message.success('Products uploaded successfully')
    }
  }

  return (
    <>
      {loading && <Loader text={loading} />}
      <BulkProductsUpload onComplete={onCompleteBulkUpload} isModalVisible={isBulkModalVisible} />
      {/* <Header /> */}
      {/* Page content */}
      <Container className="mt--7" fluid>
          <div className="col">
            <Card className="shadow border-0">
              <ButtonsContainer>
              
                <Select defaultValue="all" style={{ width: 120, textTransform: 'capitalize' }} value={selectedCategory === 'all' ? 'all' : selectedCategory.id} onChange={updateSelectedCategory}>
                  <Option value="all">All products</Option>
                  {
                    categories.map((category) => <Option key={category.id} value={category.id}>{category.name}</Option>)
                  }
                </Select>{'  '}
                <Button onClick={() => setIsBulkModalVisible(true)} type="primary"><PlusOutlined />&nbsp;Add multiple products</Button>{'  '}
                <Button onClick={addProduct} type="primary"><PlusOutlined />&nbsp;Add new product</Button>{'  '}
                {selectedCategory === 'all' && <Button onClick={addNewCategory} type="default"><PlusOutlined />&nbsp;Add new category</Button>}{'  '}
                {selectedCategory !== 'all' && <Button onClick={addNewCategory} type="secondary"><EditOutlined />&nbsp;Edit&nbsp;<b>{selectedCategory.name}</b>&nbsp;category</Button>}{'  '}
                {selectedCategory !== 'all' && (
                  <Popconfirm placement="left" title={'Are you sure to delete this category?'} onConfirm={async () => {
                    setLoading(`Deleting ${selectedCategory.name} category`);
                    await deleteCategory(selectedCategory.id);
                    await updateCategories();
                    setSelectedCategory('all');
                  }} okText="Yes" cancelText="No">
                    <Button danger color="danger"><DeleteOutlined />&nbsp;Delete&nbsp;<b>{selectedCategory.name}</b>&nbsp;category</Button>
                  </Popconfirm>
                )}
              </ButtonsContainer>
              <br />
              <br />


              {products.length > 0 ? <ProductsTable products={products} onEditProduct={editProduct} onDeleteProduct={onDeleteProduct} /> : <Empty style={{marginBottom: 20}} />}



              <CustomDrawer
                title={product ? 'Update product' : `Add new ${addingCategory ? 'category' : 'product'}`}
                closable
                placement="right"
                onClose={onClose}
                visible={visible}
              >
                {addingCategory ? <AddNewCategoryForm category={selectedCategory === 'all' ? null : selectedCategory} onCreated={onCreatedCategory} /> : <AddNewProductForm product={product} categories={categories} onCreated={fetchProducts} />}
              </CustomDrawer>
            </Card>
          </div>
      </Container>
    </>
  );
}
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
	Flex,
	Table,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
	useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import StoreService from "services/store.service";
import StoreTableRow from "components/Tables/StoreTableRow";
import { AppContext } from "AppContext";
import { message, Button } from "antd";
import CreateStoreModal from "./CreateStore";
const storeService = new StoreService();
function Stores() {
	const textColor = useColorModeValue("gray.700", "white");
	const [stores, setStores] = useState([]);
	const [isVisible, setIsVisible] = useState(false);
	const { setLoading, setLoadingText } = useContext(AppContext)

	const getAllStores = useCallback(async () => {
		try {
			setLoadingText('Loading Stores');
			setLoading(true);
			const _stores = await storeService.getAllStores();
			setStores(_stores);
			setLoading(false);
		} catch (e) {
			message.error("Something went wrong")
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		getAllStores();
	}, []);

	const onCloseModal = () => {
		getAllStores();
		setIsVisible(false)
	}

	return (
		<Flex direction="column" pt={{ base: "120px", md: "75px" }}>
			<CreateStoreModal isVisible={isVisible} onClose={onCloseModal} />
			<Card overflowX={{ sm: "scroll", xl: "hidden" }}>
				<CardHeader p="6px 0px 22px 0px">
					<Flex justifyContent="space-between" width="100%">
						<Text fontSize="xl" color={textColor} fontWeight="bold">
							Stores
						</Text>
						<Button type="primary" onClick={() => setIsVisible(true)}>Create Store</Button>
					</Flex>
				</CardHeader>
				<CardBody>
					<Table variant="simple" color={textColor}>
						<Thead>
							<Tr my=".8rem" pl="0px" color="gray.400">
								<Th pl="0px" color="gray.400">Name</Th>
								<Th color="gray.400">Status</Th>
								<Th color="gray.400">Created By</Th>
								<Th color="gray.400">Home Store</Th>
								<Th color="gray.400">Created</Th>
							</Tr>
						</Thead>
						<Tbody>
							{(stores || []).map((store) => <StoreTableRow key={store.id} store={store} />)}
						</Tbody>
					</Table>
				</CardBody>
			</Card>

		</Flex>
	);
}

export default Stores;

import { useState } from 'react';
import { Form, Input, Select, Button, message, Divider } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { Loader } from '../../components/Loader';
import ProductService from '../../services/product.service';
import { useContext } from 'react';
import { AppContext } from '../../AppContext';
import { useEffect } from 'react';
import _ from 'lodash';
import ExtrasForm from './ExtrasForm';
const { Option } = Select;
const productService = new ProductService();

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const AddNewProductForm = ({ categories = [], product, onCreated }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { store } = useContext(AppContext);
  const [extras, setExtras] = useState([])

  const onFinish = async (values) => {
    const price = parseInt(`${(parseFloat(values.price) * 100).toFixed(2)}`);
    try {
      setLoading(true);
      let newProduct = null;
      if (product) {
        let extrasToAdd = [];
        if (extras.length > 0) {
          const deletedExtras = _.difference(product.extras.map((c) => c.id), extras);
          const newExtras = _.difference(extras, product.extras.map((c) => c.id));
          for (const extraId of deletedExtras) {
            await productService.deleteProductExtra(extraId);
          }
          extrasToAdd = [...newExtras];
  
        } else if (extras.length === 0) {
          for (const extra of product.extras) {
            await productService.deleteProductExtra(extra.id);
          }
        }
        await productService.updateProduct({
          name: values.name,
          description: values.description,
          price: price,
          extras: extrasToAdd,
          outOfStock: values.outOfStock,
          productId: product.id
        })
      } else {
        const { data } = await productService.createProduct({
          ...values,
          productId: product?.id,
          price,
          extras: extras.map(extra => ({...extra, price: parseInt(extra.price * 100)})),
          storeId: store.id
        });
        newProduct = data;
      }


      if (product && (values.categories || []).length > 0) {
        const deletedCategories = _.difference(product.categories.map((c) => c.id), values.categories);
        const newCategories = _.difference(values.categories, product.categories.map((c) => c.id));
        for (const categoryId of deletedCategories) {
          await productService.deleteProductFromCategory(product.id, categoryId);
        }
        for (const categoryId of newCategories) {
          await productService.addProductToCategory(product.id, categoryId);
        }

      } else if (!product && (values.categories || []).length > 0) {
        for (const category of values.categories) {
          await productService.addProductToCategory(newProduct.id, category);
        }
      } else if (product && (values.categories || []).length === 0 && product.categories.length) {
        for (const category of product.categories) {
          await productService.deleteProductFromCategory(product.id, category.id);
        }
      }

      if (values.file) {
        await productService.addProductLogo((product || newProduct)?.id, values.file);
      }

      setLoading(false);
      message.success(`Product ${product ? 'updated' : 'created'} successfuly`);
      onCreated(product || newProduct);
    } catch (e) {
      console.log(e)
      setLoading(false);
      message.error(`Something was wrong ${product ? 'updating' : 'creating'} the product`)
    }

  };

  useEffect(() => {
    if (product) {
      form.setFieldsValue({
        name: product.name,
        description: product.description,
        price: (parseFloat(product.price) / 100).toFixed(2),
        categories: product.categories.map((c) => c.id),
        outOfStock: product.outOfStock,
      })
    } else {
      form.resetFields();
    }
  }, [product, form]);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.target.files[0];
  };

  const onExtrasFormUpdate = extras => {
    setExtras(extras)
  }
  return (
    <>
      {loading && <Loader text={`${product ? 'Updating' : 'Creating'} product`} />}
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        initialValues={{
          name: '',
          description: '',
          price: '',
          category: '',
          outOfStock: false,
        }}
        scrollToFirstError
      >

        <Form.Item
          name="name"
          label="Name"
          tooltip=""
          rules={[{ required: true, message: 'Please input the product', whitespace: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          tooltip=""
          rules={[{ required: true, message: 'Please input the product description', whitespace: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="price"
          label="Price"
          tooltip=""
          rules={[{ required: true, message: 'Please input the product price', whitespace: true }]}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item
          name="categories"
          label="Categories"
        >
          <Select mode="multiple" placeholder="Select one or more categories">
            {
              categories.map((category) => <Option key={category.id} value={category.id}>{category.name}</Option>)
            }
          </Select>
        </Form.Item>


        <Form.Item
          name="outOfStock"
          valuePropName="checked"
          {...tailFormItemLayout}
        >
          <Checkbox>
            Out of stock
          </Checkbox>
        </Form.Item>

        <Form.Item
          name="file"
          label="Product image"
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Input type="file" />
        </Form.Item>

        <Divider />
        <ExtrasForm productExtras={product ? product.extras : []} onUpdate={onExtrasFormUpdate} />

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            {product ? 'Update' : 'Create'}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default AddNewProductForm;
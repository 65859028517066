import styled from 'styled-components';
import { Drawer } from 'antd';

export const OrdersTableContainer = styled.div`
    margin-left: 10px;
    margin-right: 10px;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    button {
        margin-left: 10px;
        align-items: center;
        display: flex;
    }
    select {
        width: 150px !important;
    }

    .ant-picker-range {
        height: 3em;
        border-radius: 5px;
    }
`;

export const OrderTableTr = styled.tr`
    cursor: pointer;
    &:hover {
        background: aliceblue;
    }
`;

export const NoData = styled.div`
    padding: 20px;

`;

export const CustomDrawer = styled(Drawer)`
	.ant-drawer-content-wrapper {
		width: 520px !important;
	}    

	@media only screen and (max-width: 600px) {
		.ant-drawer-content-wrapper {
			width: 100% !important;
		}
	}
`;
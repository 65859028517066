import { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { Loader } from '../../components/Loader';
import ProductService from '../../services/product.service';
import { useContext } from 'react';
import { AppContext } from '../../AppContext';
import { useEffect } from 'react';
const productService = new ProductService();

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const AddNewCategoryForm = ({ onCreated, category }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { store } = useContext(AppContext);

  const onFinish = async ({ name }) => {
    try {
      setLoading(true);
      if (category) {
          await productService.updateCategory(category.id, name);
          onCreated();
      } else {
        const { data: newCategory } = await productService.createCategory({
          name,
          storeId: store.id
        });
        onCreated(newCategory);
      }
      setLoading(false);
      message.success(`Category ${category ? 'updated' : 'created'} successfuly`);
      
    } catch(e) {
      setLoading(false);
      message.error(e.response ? e.response.data.error.message : e.message);
    }
    
  };

  useEffect(() => {
    if (category) {
      form.setFieldsValue({
        name: category.name,
      })
    } else {
      form.resetFields();
    }
  }, [category, form])

  return (
    <>
      {loading && <Loader text={`${category ? 'Updating' : 'Creating'} category`}/>}
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
      >

        <Form.Item
          name="name"
          label="Name"
          tooltip=""
          rules={[{ required: true, message: 'Please input the product', whitespace: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            {category ? 'Edit' : 'Create'}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default AddNewCategoryForm;
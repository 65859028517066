import { request } from "../helpers/request"
const BASE_URL = '/orders'

class OrderService {
    constructor() { }
    getOne = async (orderId) => {
        const { data } = await request.get(`${BASE_URL}/${orderId}`);
        return data;
    }
    
    getOneByOrderNo = async (orderNo) => {
        const { data } = await request.get(`${BASE_URL}/pwa/${orderNo}`);
        return data;
    }

    getDashboardStats = async (storeId) => {
        const { data } = await request.get(`${BASE_URL}/stats/${storeId}`);
        return data;
    }

    getRefundOrders = async () => {
        const { data } = await request.get(`${BASE_URL}/refund`);
        return data;
    }

    getStoreOrders = async (storeId, status, startDate, endDate) => {
        const { data } = await request.get(`${BASE_URL}/store/${storeId}`, {
            params: {
                status: status === 'all' ? null : status,
                startDate: startDate || null,
                endDate: endDate || null
            }
        });
        return data;
    }

    getDriverOrders = async (driverId, status, startDate, endDate) => {
        const { data } = await request.get(`${BASE_URL}/admin/driver/${driverId}`, {
            params: {
                status: status === 'all' ? null : status,
                startDate: startDate || null,
                endDate: endDate || null
            }
        });
        return data;
    }

    getCustomerOrders = async (userId, status, startDate, endDate) => {
        const { data } = await request.get(`${BASE_URL}/admin/customer/${userId}`, {
            params: {
                status: status === 'all' ? null : status,
                startDate: startDate || null,
                endDate: endDate || null
            }
        });
        return data;
    }

    updateOrder = (orderId, status, cartItemsIds, refundStatus) => {
        return request.put(`${BASE_URL}/admin/status/${orderId}`, { status, cartItemsIds, refund: refundStatus });
    }

    updateOrderEdt = (orderId, minutes) => {
        return request.put(`${BASE_URL}/delivery-date/${orderId}`, { minutes });
    }
}
export default OrderService;

import styled from 'styled-components';

export const PlaceOption = styled.div`
	padding: 10px;
    border-bottom: 1px solid #cad1d7;
`;

export const PlaceOptionContainer = styled.div`
    box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
    border: 0;
    border-radius: 5px;
    transition: box-shadow .15s ease;
`;

export const PlacesLoading = styled.div`
    padding: 10px;
    display: flex;
    align-items: center;
    
    span {
        margin-right: 5px;
    }
`

export const LocationDataItem = styled.p`
    font-size: 14px;
`
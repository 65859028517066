import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const LoaderContainer = styled.div`
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
`;

export const Loader = ({ text }) => (
    <LoaderContainer>
        <div style={{textAlign: 'center'}}>
            <LoadingOutlined style={{fontSize: 30}} />
            {text && <p>{text}</p>}
        </div>
    </LoaderContainer>
)
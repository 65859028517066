import { Button, Checkbox, Divider, Input, List, message, Modal, Radio, Result } from "antd";
import { Loader } from "components/Loader";
import { displayCADMoney } from "helpers/utils";
import { useEffect, useState } from "react";
import OrderService from "services/order.service";
const orderService = new OrderService();

const CancelOrderModal = ({ isVisible, order = {}, onClosed }) => {
    const [refundStatus, setRefundStatus] = useState();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [, setCounter] = useState(0);
    const [orderNo, setOrderNo] = useState('');
    const [cartItems, setCartItems] = useState([]);

    useEffect(() => {
        setCartItems((order.cart || {}).cartItems);
        setRefundStatus(undefined);
        setSuccess(false);
        setOrderNo('')
    }, [order])

    const onCartItemUpdated = itemId => {
        setCartItems(prev => {
            for (const item of prev) {
                if (item.id === itemId) {
                    item.checked = !item.checked;
                }
            }
            return prev;
        });
        setCounter(prev => prev += 1);
    }

    const calculateOrderTotal = () => {
        const amounts = cartItems.filter(item => item.checked).map(item => parseInt(item.total))
        return amounts.length ? amounts.reduce((acc, value) => acc += value) : 0;
    }

    const voidOrder = async () => {
        try {
            if (!refundStatus) return;
            setLoading(true);
            const cartItemsIds = cartItems.filter(item => item.checked).map(item => item.id) || [];
            await orderService.updateOrder(order.id, 'VOIDED', cartItemsIds, refundStatus);
            setSuccess(true);
            setLoading(false);
        } catch (e) {
            setLoading(false);
            message.error("Something was wrong cancelling the order, please try again in a while")
        }
    }

    return (
        <Modal title={`Cancelling order #${order.orderNo}`} onCancel={onClosed} footer={null} visible={isVisible}>
            {loading && <Loader text="Updating order" />}
            {
                success ? (
                    <Result
                        status="success"
                        title="Successfully order cancelled!"
                        subTitle="The customer will be notified and refunded based on your choose"
                    />
                ) : (
                    <>
                        <Radio.Group onChange={e => setRefundStatus(e.target.value)} value={refundStatus}>
                            <Radio value={'NO_REFUND'}>No refund</Radio>
                            <Radio value={'FULL'}>Full Refund</Radio>
                            <Radio value={'PARTIALLY'}>Partial Refund</Radio>
                        </Radio.Group>

                        {
                            refundStatus === 'PARTIALLY' && (
                                <>
                                    <Divider orientation="left"></Divider>
                                    <List
                                        header={<div>Order items</div>}
                                        footer={<div><b>Total to be refunded:</b> {displayCADMoney(calculateOrderTotal())}</div>}
                                        bordered
                                        dataSource={cartItems}
                                        renderItem={item => (
                                            <List.Item>
                                                <Checkbox checked={item.checked} onChange={() => onCartItemUpdated(item.id)}>{item.product.name} x {item.quantity} - {displayCADMoney(item.total)}</Checkbox>
                                            </List.Item>
                                        )}
                                    />
                                </>
                            )
                        }
                        <Divider orientation="left"></Divider>
                        <Input value={orderNo} onChange={e => setOrderNo(e.target.value)} placeholder="Please enter the order number" />
                        <br />
                        <br />
                        <Button onClick={voidOrder} disabled={refundStatus === undefined || parseInt(orderNo) !== order.orderNo} type="primary" danger>Cancel order</Button>
                    </>
                )
            }
        </Modal>
    )
}

export default CancelOrderModal;
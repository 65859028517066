import { request } from "../helpers/request"
const BASE_URL = '/config';
class SettingService {
    constructor() { }

    addSetting = (key, value) => {
        return request.post(`${BASE_URL}`, {
            key,
            value
        })
    }

    updateSetting = (keyId, value) => {
        return request.put(`${BASE_URL}/${keyId}`, {
            value
        })
    }

    getSettings = () => {
        return request.get(`${BASE_URL}`)
    }

}


export default SettingService;
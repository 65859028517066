import { Tag } from "antd";

const OrderStatus = {
	PENDING: <Tag color="yellow">Pending</Tag>,
	READY_FOR_DRIVER: <Tag color="geekblue">Ready for driver</Tag>,
	READY_FOR_PICKUP: <Tag color="geekblue">Ready for pickup</Tag>,
	IN_PROGRESS: <Tag color="geekblue">In progress</Tag>,
	ON_ROUTE_TO_STORE: <Tag color="geekblue">On route to store</Tag>,
	ON_ROUTE_TO_CUSTOMER: <Tag color="geekblue">On route to customer</Tag>,
	COMPLETED: <Tag color="#87d068">Completed</Tag>,
	PAID: <Tag color="green">Paid</Tag>,
	REFUNDED: <Tag color="#f50">Refunded</Tag>,
	PARTIALLY: <Tag color="#f50">Partially refunded</Tag>,
	VOIDED: <Tag color="gray">Voided</Tag>,
}

export default OrderStatus;
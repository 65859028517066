import { request } from "../helpers/request"
const BASE_URL = '/users';
class UserService {
    constructor() { }

    getUserInfo = async () => {
        const { data } = await request.get(`${BASE_URL}`);
        return data;
    }

    getTwilioToken = async () => {
        const { data } = await request.get(`${BASE_URL}/twilio-token-admin`);
        return data;
    }

    getDriverAccountInfo = async (driverId) => {
        const { data } = await request.get(`${BASE_URL}/admin/driver-account-stripe-information/${driverId}`);
        return data;
    }

    getOne = async (userId) => {
        const { data } = await request.get(`${BASE_URL}/admin/${userId}`);
        return data;
    }

    getUsersByRole = async role => {
        const { data } = await request.get(`${BASE_URL}/all?role=${role}`);
        return data;
    }

    approveOrRejectDriver = async (driverId, status, rejectReason) => {
        return await request.put(`${BASE_URL}/approver-or-reject-driver/${driverId}`, {
            status,
            rejectReason
        });
    }

    updateUser = async (data) => {
        return await request.put(`${BASE_URL}`, data);
    }

    deleteUser = async (userId) => {
        return request.delete(`${BASE_URL}/${userId}`);
    }

    payToDriver = async (driverId, amount) => {
        return request.post(`${BASE_URL}/payout/${driverId}`, { amount });
    }

    createUser = async (role, newUser) => {
        const OPTIONS = {
            ADMIN: '/admin',
            CUSTOMER: '',
            DRIVER: '/driver',
            STORE_OWNER: '/store-user',
            STORE_EMPLOYEE: '/store-user',
        }
        return await request.post(`${BASE_URL}${OPTIONS[role]}`, newUser);
    }

    login = (email, password) => {
        return request.post(`${BASE_URL}/login`, {
            email,
            password
        })
    }

    initResetPassword = email => {
        return request.post(`${BASE_URL}/init-reset-password`, { email })
    }

    resetPassword = (newPassword, token) => {
        return request.post(`${BASE_URL}/reset-password`, { newPassword, token })
    }
}


export default UserService;
import { useState } from 'react';
import { Typography, Form, Input, Button, message, Modal } from 'antd';
import ProductService from '../../services/product.service';
import { useContext } from 'react';
import { AppContext } from '../../AppContext';
import { LoadingOutlined } from '@ant-design/icons'
import { BASE_URL } from 'helpers/utils';
const { Text, Link } = Typography;
const productService = new ProductService();

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 },
	},
};
const tailFormItemLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0,
		},
		sm: {
			span: 16,
			offset: 8,
		},
	},
};

const BulkProductsUpload = ({ isModalVisible, onComplete }) => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const { store } = useContext(AppContext);

	const normFile = (e) => {
		if (Array.isArray(e)) {
			return e;
		}
		return e && e.target.files[0];
	};

	const onFinish = async ({ file }) => {
		try {
			setLoading(true);
			await productService.bulkUploadCsv(store.id, file);
			setLoading(false);
			onComplete("completed");
		} catch (e) {
			setLoading(false);
			message.error('Something failed, please check the format on your csv file')
		}
	}

	return (
		<Modal footer={null} title="Bulk Upload" visible={isModalVisible} onOk={onComplete} onCancel={onComplete}>
			{
				loading ? (
					<Text><LoadingOutlined />&nbsp;Uploading products...</Text>
				) : (
					<>
						<Form
							{...formItemLayout}
							form={form}
							name="Bulk upload"
							onFinish={onFinish}
							scrollToFirstError
						>

							<Form.Item
								name="file"
								label="CSV file"
								valuePropName="fileList"
								getValueFromEvent={normFile}
							>
								<Input type="file" />
							</Form.Item>


							<Form.Item {...tailFormItemLayout}>
								<Button type="primary" htmlType="submit">
									Upload
								</Button>
							</Form.Item>
						</Form>
						<Text>Download the csv file template <Link href={`${BASE_URL}/api/v1/grubx-csv-products-template`} target="_blank">here</Link></Text>
					</>
				)
			}
		</Modal>
	);
};

export default BulkProductsUpload;
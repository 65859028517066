import { Button, Input, Select, message, Modal, Form, Result, Divider } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
const { Option } = Select;
import { Loader } from "components/Loader";
import _ from "lodash";
import { useEffect, useState } from "react";
import { LocationDataItem, PlaceOption, PlaceOptionContainer, PlacesLoading } from "./styled";
import StoreService from "services/store.service";
const storeService = new StoreService();
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};
const prefixSelector = (
    <Form.Item name={['user', "prefix"]} noStyle>
        <Select style={{ width: 70 }}>
            <Option value="1">+1</Option>
        </Select>
    </Form.Item>
);
const AddLocationModal = ({ isVisible, storeId, onClose }) => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [address, setAddress] = useState();
    const [locationData, setLocationData] = useState({});
    const [provinces, setProvinces] = useState([]);
    const [form] = Form.useForm();



    useEffect(() => {
        const getProvinces = async () => {
            try {
                setLoading(true);
                const response = await storeService.getProvinces();
                setProvinces(response);
                setLoading(false);
            } catch (e) {
                message.error("Something went wrong")
                setLoading(false);
            }
        };
        getProvinces();
    }, [])

    const handleChange = address => {
        setLocationData({})
        setAddress(address)
    };

    useEffect(() => {
        form.resetFields();
    }, [form]);

    const handleSelect = async address => {
        try {
            setAddress(address)
            const results = await geocodeByAddress(address)
            const country = results[0].address_components.find(ac => ac.types.indexOf('country') !== -1);
            const city = results[0].address_components.find(ac => ac.types.indexOf('locality') !== -1);
            const province = results[0].address_components.find(ac => ac.types.indexOf("administrative_area_level_1") !== -1);
            const latLng = await getLatLng(results[0]);
            setLocationData({
                country: country.long_name,
                countryCode: country.short_name,
                province: province.long_name,
                city: city.long_name,
                lat: latLng.lat,
                lng: latLng.lng,
            })
        } catch (e) {
        }
    };

    const onCancel = () => {
        setSuccess(false);
        onClose();
    }

    const addLocation = async ({ location, province }) => {
        try {
            if (!province) return;
            setLoading(true);
            await storeService.addNewLocation(storeId, {
                ...locationData,
                latitude: locationData.lat,
                longitude: locationData.lng,
                country: locationData.countryCode,
                province: province,
                address,
                name: location.name,
                phone: location.phone
            });
            setLoading(false);
            message.success("Location added successfuly");
            form.resetFields();
            onClose();
        } catch (e) {
            setLoading(false);
            message.error("Error adding location");
        }

    }

    return (
        <Modal title={'Add location'} onCancel={onCancel} footer={null} visible={isVisible}>
            {loading && <Loader text={"Adding location..."} />}
            {
                success ? (
                    <Result
                        status="success"
                        title={`Successfully location added}!`}
                    />
                ) : (
                    <>
                        <Form form={form} layout="vertical" {...layout} name="nest-messages" onFinish={addLocation} validateMessages={validateMessages}>
                            <Form.Item>
                                <PlacesAutocomplete
                                    value={address}
                                    onChange={handleChange}
                                    onSelect={handleSelect}
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-address"
                                            >
                                                <span style={{ color: 'red' }}>*</span> Address
                                            </label>
                                            <Input
                                                id="input-address"
                                                {...getInputProps({
                                                    placeholder: 'Search an address ...',
                                                    className: 'location-search-input',
                                                })}
                                            />
                                            <PlaceOptionContainer className="autocomplete-dropdown-container">
                                                {loading && <PlacesLoading><LoadingOutlined />&nbsp;Loading...</PlacesLoading>}
                                                {suggestions.map(suggestion => {
                                                    const className = suggestion.active
                                                        ? 'suggestion-item--active'
                                                        : 'suggestion-item';
                                                    // inline style for demonstration purpose
                                                    const style = suggestion.active
                                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                    return (
                                                        <PlaceOption
                                                            key={suggestion.placeId}
                                                            {...getSuggestionItemProps(suggestion, {
                                                                className,
                                                                style,
                                                            })}
                                                        >
                                                            <span>{suggestion.description}</span>
                                                        </PlaceOption>
                                                    );
                                                })}
                                            </PlaceOptionContainer>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                            </Form.Item>
                            <Form.Item name={['location', 'name']} label="Location Name" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name={['location', 'phone']}
                                label="Phone"
                                rules={[{ required: true, message: 'Please input the phone number of your location' }]}
                            >
                                <Input addonBefore={prefixSelector} style={{ width: '100%' }} />
                            </Form.Item>

                            <Form.Item name="province" label="Province" rules={[{ required: true }]}>
                                <Select placeholder="Select one">
                                    {
                                        provinces.map((province) => <Option key={province} value={province}>{province}</Option>)
                                    }
                                </Select>
                            </Form.Item>

                            <Divider />

                            {
                                locationData.city && <>
                                    <label className="form-control-label">Details</label>
                                    <LocationDataItem>City: {locationData.city}</LocationDataItem>
                                    <LocationDataItem>Province: {locationData.province}</LocationDataItem>
                                    <LocationDataItem>Country: {locationData.country}</LocationDataItem>
                                    <LocationDataItem>Latitude: {locationData.lat}</LocationDataItem>
                                    <LocationDataItem>Longitude: {locationData.lng}</LocationDataItem>
                                </>
                            }

                            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                                <Button type="primary" htmlType="submit">
                                    Add
                                </Button>
                            </Form.Item>
                        </Form>
                    </>
                )
            }
        </Modal>
    )
}

export default AddLocationModal;
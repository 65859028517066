import { Button, Input, message, Modal, Form, Result } from "antd";
import { Loader } from "components/Loader";
import { useState } from "react";
import SettingService from "services/settings.service";
const settingsService = new SettingService();
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};
const AddSettingModal = ({ isVisible, setting = {}, onClose }) => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [form] = Form.useForm();

    const createCategory = async ({ setting: formData }) => {
        try {
            let newSetting = {};
            setLoading(true);
            if (setting.id) {
                newSetting = await settingsService.updateSetting(setting.id, formData.value);

            } else {
                newSetting = await settingsService.addSetting(formData.key, formData.value);
            }
            setSuccess(true);
            setLoading(false);
        } catch (e) {
            setLoading(false);
            message.error("Something was wrong creating the setting, please try again in a while")
        }
    }

    const onCancel = () => {
        form.resetFields();
        setSuccess(false);
        onClose();
    }

    return (
        <Modal title={setting.id ? 'Update setting' : 'Create new setting'} onCancel={onCancel} footer={null} visible={isVisible}>
            {loading && <Loader text={setting.id ? "Updating setting" : "Creating setting"} />}
            {
                success ? (
                    <Result
                        status="success"
                        title={`Successfully setting ${setting.id ? 'updated' : 'created'}!`}
                    />
                ) : (
                    <>
                        <Form form={form} initialValues={{ setting: setting.id ? { ...setting } : {} }} {...layout} name="nest-messages" onFinish={createCategory} validateMessages={validateMessages}>
                            <Form.Item name={['setting', 'key']} label="Name" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name={['setting', 'value']} label="Value" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                                <Button type="primary" htmlType="submit">
                                    {setting.id ? 'Update' : 'Create'}
                                </Button>
                            </Form.Item>
                        </Form>
                    </>
                )
            }
        </Modal>
    )
}

export default AddSettingModal;
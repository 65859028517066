import React, { useCallback, useEffect, useState } from "react";
import { Flex, Text, useColorModeValue, Box } from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { Loader } from "components/Loader";
import { Descriptions, message, Tabs } from "antd";
import { useParams } from "react-router";
import UserService from "services/user.service";
import OrdersTab from "components/OrdersTab";
const { TabPane } = Tabs;
const userService = new UserService();

function CustomerInfo() {
    const textColor = useColorModeValue("gray.700", "white");
    const [customer, setCustomer] = useState({ });
    const { userId } = useParams();
    const [loading, setLoading] = useState(false);

    const getCustomer = useCallback(async () => {
        try {
            setLoading(true);
            const data = await userService.getOne(userId);
            setCustomer(data);
            setLoading(false);
        } catch (e) {
            message.error("Something went wrong")
            setLoading(false);
        }
    }, [userId]);

    useEffect(() => {
        getCustomer();
    }, []);

    return (
        <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
            {loading && <Loader text="Loading..." />}
            <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
                <CardHeader p="6px 0px 22px 0px">
                    <Text fontSize="xl" color={textColor} fontWeight="bold">
                        {customer.firstName} {customer.lastName}
                    </Text>
                </CardHeader>
                <CardBody>
                    <Box>
                        <Descriptions title="Customer Info" >
                            <Descriptions.Item label="Email">{customer.email}</Descriptions.Item>
                            <Descriptions.Item label="Phone">+{customer.countryCode} {customer.phone}</Descriptions.Item>
                        </Descriptions>
                        <Tabs defaultActiveKey="orders" onChange={console.log}>
                            <TabPane tab={"Orders"} key={"orders"}>
                                <OrdersTab customerId={customer.id} />
                            </TabPane>
                        </Tabs>
                    </Box>
                </CardBody>
            </Card>

        </Flex>
    );
}

export default CustomerInfo;

import React from "react";
import {
    Table,
    Tbody,
    Th,
    Thead,
    Td,
    useColorModeValue,
    Tr,
} from "@chakra-ui/react";
import moment from "moment";

function SettingsTable({ settings, editSetting = () => {} }) {
	const textColor = useColorModeValue("gray.700", "white");
    return (
        <Table variant="simple" color={textColor}>
            <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400">
                    <Th pl="0px" color="gray.400">Name</Th>
                    <Th pl="0px" color="gray.400">Value</Th>
                    <Th color="gray.400">Updated</Th>
                    <Th color="gray.400">Created</Th>
                </Tr>
            </Thead>
            <Tbody>
                {(settings || []).map((setting) => {
                    return (
                        <Tr style={{ cursor: 'pointer' }} onClick={() => editSetting(setting)} key={setting.id}>

                            <Td>{setting.key}</Td>
                            <Td>{setting.value}</Td>
                            <Td>{moment(setting.dateUpdated).format("DD/MM/YYYY HH:MM")}</Td>
                            <Td>{moment(setting.dateCreated).format("DD/MM/YYYY HH:MM")}</Td>
                        </Tr>
                    );
                })}
            </Tbody>
        </Table>
    );
}

export default SettingsTable;

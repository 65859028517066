import React, { useContext, useEffect, useRef, useState } from "react";
import {
    Flex,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import TwilioChat from "twilio-chat";
import { AppContext } from "AppContext";
import UserService from "services/user.service";
import { ChatList, SideBar, Input, Button as ChatButton } from 'react-chat-elements'
import { ChatFeed, Message } from 'react-chat-ui'
import customerIcon from 'assets/img/customer.svg'
import moment from "moment";
import styled from 'styled-components'

const userService = new UserService();
const MessagesContainer = styled.div`
    padding: 0px 20px;
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: column;
`;

const UserInformation = styled.div`
    padding: 0px 20px;
    width: 100%;
`;


const ChatContainer = styled.div`
    display: flex;
    width: 100%;
    .rce-sbar {
        justify-content: flex-start;
    }
`;

function Chats() {
    const textColor = useColorModeValue("gray.700", "white");
    const [chatClient, setChatClient] = useState(null);
    const [channels, setChannels] = useState([]);
    const [openedChannel, setOpenedChannel] = useState();
    const [messages, setMessages] = useState([]);
    const [adminIdentity, setAdminIdentity] = useState([]);
    const [user, setUser] = useState({});
    const [, setCounter] = useState(1)
    const inputRef = useRef();
    const { setLoading, setLoadingText } = useContext(AppContext)

    useEffect(() => {
        const init = async () => {
            try {
                setLoadingText("Loading...");
                setLoading(true);
                const token = await userService.getTwilioToken();
                const client = new TwilioChat(token.jwt);
                setAdminIdentity(token.identity);
                client.on("tokenAboutToExpire", async () => {
                    const token = await userService.getTwilioToken();
                    // client.updateToken(token.jwt);
                });

                client.on("tokenExpired", async () => {
                    const token = await userService.getTwilioToken();
                    // client.updateToken(token.jwt);
                });

                setChatClient(client);
            } catch (e) {
                setLoading(false);
            }
        }
        init();
    }, []);

    useEffect(() => {
        const getSubscribedChannels = async () => {
            if (chatClient) {
                const channels = await chatClient.getSubscribedChannels();
                setChannels(channels.items || []);
                setLoading(false);
            }
        }

        getSubscribedChannels();

    }, [chatClient]);

    const addMessage = ({ author, body, userName }) => {
        const message = new Message({
            id: author === adminIdentity ? 0 : 1,
            message: body,
            senderName: userName
        });
        setMessages(prev => {
            prev.push(message)
            return prev;
        });
        setCounter(prev => prev + 1)
    }

    const openChannel = async _channel => {
        try {
            setLoading(true);
            const channel = await chatClient.getChannelBySid(_channel.sid);
            const messages = await channel.getMessages();
            try {
                const user = await userService.getOne(channel.channelState.uniqueName.replace(adminIdentity, ''))
                setUser(user);
            } catch (e) {
                setUser({})
            }

            setMessages(messages.items.map(m => ({
                senderName: <a target="_blank" href={m.state.attributes.storeId ? `/admin/stores/${m.state.attributes.storeId}` : `/admin/customers/${m.state.attributes.userId}`}>{m.state.attributes.userName}</a>,
                id: m.state.author === adminIdentity ? 0 : 1,
                message: m.state.body,
                index: m.state.index,
            })))
            setOpenedChannel(channel);
            channel.on('messageAdded', ({ author, body, attributes }) => {
                addMessage({ author, body, userName: attributes.userName })
            })
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    }

    const deleteChannel = async () => {
        await chatClient.deleteChannel(openedChannel.sid)
    }

    useEffect(() => {
        if (messages.length > 0 && openedChannel) {
            const index = messages[messages.length - 1].index;
            if (index) {
                openedChannel.updateLastConsumedMessageIndex(index);
            }
        }
    }, [messages, openedChannel]);

    const sendMessage = (e) => {
        e.preventDefault();
        openedChannel.sendMessage(inputRef.current.input.value, {
            userId: adminIdentity,
            userName: 'Grubx Support'
        })
        inputRef.current.input.value = "";
    }

    return (
        <Flex style={{ height: '100vh' }} direction="column" pt={{ base: "120px", md: "75px" }}>
            <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
                <CardHeader>
                    <Flex justifyContent="space-between" width="100%">
                        <Text fontSize="xl" color={textColor} fontWeight="bold">
                            Chats
                        </Text>
                    </Flex>
                </CardHeader>
                <CardBody style={{ diplay: 'block' }}>
                    <ChatContainer>
                        <SideBar
                            type="light"
                            center={
                                <div>
                                    <ChatList
                                        className='chat-list'
                                        dataSource={channels.map(channel => ({
                                            avatar: customerIcon,
                                            title: channel.channelState.friendlyName,
                                            subtitle: 'Last message: ' + moment(channel.channelState.lastMessage ? channel.channelState.lastMessage.dateCreated : new Date()).fromNow(),
                                            date: channel.channelState.dateCreated,
                                            unread: channel.channelState.lastConsumedMessageIndex !== channel.channelState.lastMessage.index,
                                            sid: channel.sid
                                        }))}
                                        onClick={openChannel}

                                    />
                                </div>
                            } />
                        <MessagesContainer>
                            {user.id && <UserInformation>
                                <p><b>Name:</b> {user.firstName} {user.lastName}</p>
                                <p><b>Role:</b> {user.role}</p>
                                <p><a target="_blank" href={`${user.role === "DRIVER" ? '/admin/drivers/' : '/admin/customers/'}${user.id}`}>More Information</a></p>
                            </UserInformation>}
                            <ChatFeed
                                messages={messages} // Array: list of message objects
                                hasInputField={false} // Boolean: use our input, or use your own
                                showSenderName // show the name of the user who sent the message
                                bubblesCentered={false} //Boolean should the bubbles be centered in the feed?
                                // JSON: Custom bubble styles
                                bubbleStyles={
                                    {
                                        text: {
                                            fontSize: 18
                                        },
                                        chatbubble: {
                                            borderRadius: 70,
                                            padding: 10
                                        }
                                    }
                                }
                            />
                            <form onSubmit={sendMessage}>
                                <Input
                                    placeholder="Type here..."
                                    multiline={false}
                                    ref={inputRef}
                                    rightButtons={
                                        <ChatButton
                                            color='white'
                                            backgroundColor='black'
                                            text='Send' />
                                    }
                                />
                            </form>
                        </MessagesContainer>
                    </ChatContainer>
                </CardBody>
                
            </Card>

        </Flex>
    );
}

export default Chats;

import {
	Table,
	Tbody,
	Th,
	Thead,
	Td,
	Tr,
} from "@chakra-ui/react";
import { OrdersTableContainer, OrderTableTr } from './styled';
import moment from 'moment';
import OrderStatus from "components/OrderStatus";
import RefundedStatus from "components/RefundedStatus";
import OrderType from "components/OrderType";
import { Tag } from 'antd'

const OrdersTable = ({ orders = [], onOrderOpened }) => {
	return (
		<OrdersTableContainer>
			<Table className="align-items-center table-flush" responsive>
				<Thead className="thead-light">
					<Tr>
						<Th scope="col">Order Number</Th>
						<Th scope="col">Created</Th>
						<Th scope="col">Total</Th>
						<Th scope="col">Ordered By</Th>
						<Th scope="col">Payment Method</Th>
						<Th scope="col">Type</Th>
						<Th scope="col">Status</Th>
					</Tr>
				</Thead>
				<Tbody>
					{orders.map(order => {
						return (
							<OrderTableTr key={order.id} onClick={() => onOrderOpened(order)}>
								<Td>{order.orderNo}</Td>
								<Td>{moment(order.dateCreated).fromNow()}</Td>
								<Td>{(parseFloat(order.total) / 100).toFixed(2)}$</Td>
								<Td>{order.user ? `${order.user.firstName} ${order.user.lastName}` : order.userName}</Td>
								<Td>{order.stripeTransactionId === "CASH" ? <Tag color="lime">Cash</Tag> : <Tag color="#5469d4">Stripe</Tag>}</Td>
								<Td>{OrderType[order.type]}</Td>
								<Td style={{ textTransform: 'uppercase' }}>{RefundedStatus[order.refundedStatus]} {OrderStatus[order.status]}</Td>
							</OrderTableTr>
						)
					})}
				</Tbody>
			</Table>
		</OrdersTableContainer>
	)
}

export default OrdersTable;
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Flex, Text, useColorModeValue, } from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import StoreService from "services/store.service";
import { AppContext } from "AppContext";
import { message, Button } from "antd";
import CreateCategoryModal from "./CreateCategory";
import StoreCategoriesTable from "./StoreCategoriesTable";

const storeService = new StoreService();
function StoreCategories() {
	const textColor = useColorModeValue("gray.700", "white");
	const [categories, setCategories] = useState([]);
	const [category, setCategory] = useState({});
	const [isVisible, setIsVisible] = useState(false);
	const { setLoading, setLoadingText } = useContext(AppContext)

	const getCategories = useCallback(async () => {
		try {
			setLoadingText('Loading Stores');
			setLoading(true);
			const response = await storeService.getCategories();
			setCategories(response.data);
			setLoading(false);
		} catch (e) {
			message.error("Something went wrong")
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		getCategories();
	}, []);

	const onCloseModal = () => {
		setCategory({});
		getCategories();
		setIsVisible(false)
	}

	const editCategory = category => setCategory(category);

	useEffect(() => {
		if (category.id) setIsVisible(true);
	}, [category])

	return (
		<Flex direction="column" pt={{ base: "120px", md: "75px" }}>
			{isVisible && <CreateCategoryModal category={category} isVisible={isVisible} onClose={onCloseModal} />}
			<Card overflowX={{ sm: "scroll", xl: "hidden" }}>
				<CardHeader p="6px 0px 22px 0px">
					<Flex justifyContent="space-between" width="100%">
						<Text fontSize="xl" color={textColor} fontWeight="bold">
							Categories
						</Text>
						<Button type="primary" onClick={() => setIsVisible(true)}>Create Category</Button>
					</Flex>
				</CardHeader>
				<CardBody>
					<StoreCategoriesTable editCategory={editCategory} categories={categories} />
				</CardBody>
			</Card>

		</Flex>
	);
}

export default StoreCategories;

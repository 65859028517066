import React, { useEffect, useState } from 'react';
import UserService from './services/user.service';
import { Loader } from 'components/Loader';
import { isLogged } from 'helpers/utils';
const AppContext = React.createContext({});
const userService = new UserService();


const AppContextProvider = ({ children }) => {
	const [user, setUser] = useState({});
	const [store, setStore] = useState({});
	const [currentDriver, setCurrentDriver] = useState({});
	const [loading, setLoading] = useState(false);
	const [loadingText, setLoadingText] = useState(null);

	const getUser = async () => {
		try {
			setLoading(true);
			const _user = await userService.getUserInfo();
			setUser(_user);
			setLoading(false);
		} catch (e) {
			setLoading(false);
		}

	};

	useEffect(() => {
		if (isLogged()) getUser();
	}, []);

	return (
		<AppContext.Provider
			value={{
				user,
				setUser,
				setLoading,
				loading,
				store,
				setStore,
				setLoadingText,
				currentDriver, setCurrentDriver
			}}
		>
			{loading && <Loader text={loadingText || 'Loading...'} />}
			{children}
		</AppContext.Provider>
	);
};

export { AppContext, AppContextProvider };

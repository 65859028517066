import { request } from "../helpers/request"
const BASE_URL = '/products'

class ProductService {

    getOne = async (productId) => {
        const { data } = await request.get(`${BASE_URL}/${productId}`);
        return data;
    }
    
    getCategory = async (categoryId) => {
        const { data } = await request.get(`${BASE_URL}/category/${categoryId}`);
        return data;
    }
    
    createProduct = (product) => {
        return request.post(`${BASE_URL}`, product)
    }
    
    updateProduct = (product) => {
        return request.put(`${BASE_URL}/${product.productId}`, product)
    }
    
    createCategory = (category) => {
        return request.post(`${BASE_URL}/category`, category)
    }
    
    updateCategory = (categoryId, name) => {
        return request.put(`${BASE_URL}/category/${categoryId}`, {
            name
        })
    }
    
    deleteCategory = (categoryId) => {
        return request.delete(`${BASE_URL}/category/${categoryId}`);
    }
    
    addProductToCategory = (productId, categoryId) => {
        return request.put(`${BASE_URL}/${productId}/category/${categoryId}`)
    }
    
    deleteProductFromCategory = (productId, categoryId) => {
        return request.delete(`${BASE_URL}/${productId}/category/${categoryId}`)
    }
    
    deleteProductExtra = (extraId) => {
        return request.delete(`${BASE_URL}/extra/${extraId}`)
    }
    
    deleteProduct = (productId) => {
        return request.delete(`${BASE_URL}/${productId}`)
    }
    
    addProductLogo = (productId, file) => {
        const formData = new FormData();
        formData.append("logo", file);
        return request.put(`${BASE_URL}/${productId}/logo`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }
    
    bulkUploadCsv = (storeId, file) => {
        const formData = new FormData();
        formData.append("csv", file);
        return request.post(`${BASE_URL}/${storeId}/csv`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }
}


export default ProductService;

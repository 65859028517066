import { Button, Checkbox, Divider, Input, Select, message, Modal, Form, Result } from "antd";
const { Option } = Select;
import { Loader } from "components/Loader";
import { displayCADMoney } from "helpers/utils";
import { useEffect, useState } from "react";
import OrderService from "services/order.service";
import StoreService from "services/store.service";
import UserService from "services/user.service";
const orderService = new OrderService();
const userService = new UserService();
const storeService = new StoreService();
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const prefixSelector = (
    <Form.Item name={['user', "prefix"]} noStyle>
        <Select style={{ width: 70 }}>
            <Option value="1">+1</Option>
        </Select>
    </Form.Item>
);
/* eslint-disable no-template-curly-in-string */
const validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};
const AddUserModal = ({ isVisible, predefinedRole, user = {}, onClose }) => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [role, setRole] = useState(predefinedRole || "ADMIN");

    const createUser = async ({ user }) => {
        try {
            setLoading(true);
            await userService.createUser(role, {
                ...user,
                countryPhone: '1',
                role: role,
            });
            setSuccess(true);
            setLoading(false);
        } catch (e) {
            setLoading(false);
            message.error(e.response ? e.response.data.error.message : "Something went wrong")
        }
    }

    const onValuesChange = ({ user }) => {
        if (user.role) setRole(user.role)
    }

    const onCancel = () => {
        setSuccess(false);
        onClose();
    }

    return (
        <Modal title={`Create ${role.replace("_", " ").toLowerCase()}`} onCancel={onCancel} footer={null} visible={isVisible}>
            {loading && <Loader text="Creating user" />}
            {
                success ? (
                    <Result
                        status="success"
                        title="Successfully user created!"
                        subTitle="You can share the user password set or join an user to a store"
                    />
                ) : (
                    <>
                        <Form onValuesChange={onValuesChange} initialValues={{ user: { role: "ADMIN" } }} {...layout} name="nest-messages" onFinish={createUser} validateMessages={validateMessages}>
                            <Form.Item name={['user', 'firstName']} label="First name" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name={['user', 'lastName']} label="Last name" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            {!predefinedRole && <Form.Item name={['user', 'role']} label="Role" rules={[{ required: true }]}>
                                <Select style={{ width: 120 }}>
                                    <Option value="ADMIN">Admin</Option>
                                    <Option value="CUSTOMER">Customer</Option>
                                    <Option value="STORE_OWNER">Store owner</Option>
                                    <Option value="STORE_EMPLOYEE">Store employee</Option>
                                    <Option value="DRIVER">Driver</Option>
                                </Select>
                            </Form.Item>}
                            <Form.Item name={['user', 'email']} label="Email" rules={[{ required: true, type: 'email' }]}>
                                <Input />
                            </Form.Item>
                             {role !== "ADMIN" && <Form.Item
                                name={['user', 'phone']}
                                label="Phone"
                                rules={[{ required: true, message: 'Please input your phone number!' }]}
                            >
                                <Input addonBefore={prefixSelector} style={{ width: '100%' }} />
                            </Form.Item>}
                            {["ADMIN", "STORE_OWNER", "STORE_EMPLOYEE"].indexOf(role) !== -1 &&<Form.Item name={['user', 'password']} label="Password" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>}
                            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                                <Button type="primary" htmlType="submit">
                                    Create
                                </Button>
                            </Form.Item>
                        </Form>
                    </>
                )
            }
        </Modal>
    )
}

export default AddUserModal;
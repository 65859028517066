import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
    Flex,
    Table,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import StoreTableRow from "components/Tables/StoreTableRow";
import TwilioChat from "twilio-chat";
import { AppContext } from "AppContext";
import { Button } from "antd";
import UserService from "services/user.service";
import { ChatList, SideBar, Input, Button as ChatButton } from 'react-chat-elements'
import { ChatFeed, Message } from 'react-chat-ui'
import driverIcon from 'assets/img/driver.svg'
import customerIcon from 'assets/img/customer.svg'
import moment from "moment";
import styled from 'styled-components'
import OrderService from "services/order.service";
import OrdersTab from "components/OrdersTab";
import OrdersTable from "views/Orders/OrdersTable";
import { CustomDrawer } from "views/Orders/styled";
import OrderInformation from "views/Orders/OrderInformation";

const userService = new UserService();
const orderService = new OrderService();
const MessagesContainer = styled.div`
    padding: 0px 20px;
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: column;
`;

const UserInformation = styled.div`
    padding: 0px 20px;
    width: 100%;
`;

function Refunds() {
    const { setLoading, setLoadingText } = useContext(AppContext)
    const textColor = useColorModeValue("gray.700", "white");
    const [orders, setOrders] = useState([]);
    const [order, setOrder] = useState({});
    

    const fetchOrders = async () => {
        try {
            setLoadingText("Loading...");
            setLoading(true);
            const _orders = await orderService.getRefundOrders();
            setOrders(_orders);
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    }
    useEffect(() => {
        fetchOrders();
    }, []);

    const onOrderOpened = async order => {
        setOrder(order)
    }
    
    const onClose = () => {
        setOrder({});
        fetchOrders();
    }

    return (
        <Flex style={{ height: '100vh' }} direction="column" pt={{ base: "120px", md: "75px" }}>
            <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
                <CardHeader p="6px 0px 22px 0px">
                    <Flex justifyContent="space-between" width="100%">
                        <Text fontSize="xl" color={textColor} fontWeight="bold">
                            Refund Requests
                        </Text>
                    </Flex>
                </CardHeader>
                <CardBody>
                    <OrdersTable onOrderOpened={onOrderOpened} orders={orders} />
                    <CustomDrawer
                        title={`Order #${(order || {}).orderNo}`}
                        width={520}
                        placement="right"
                        onClose={onClose}
                        visible={order.id !== undefined}
                    >
                        <OrderInformation onOrderUpdated={fetchOrders} order={order} />
                    </CustomDrawer>
                </CardBody>
            </Card>

        </Flex>
    );
}

export default Refunds;

import React, { useCallback, useContext, useEffect, useState } from "react";
import { Flex, Text, useColorModeValue, } from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { AppContext } from "AppContext";
import { message, Button } from "antd";
import AddSettingModal from "./AddSettingModal";
import SettingsTable from "./SettingsTable";
import SettingService from "services/settings.service";

const storeService = new SettingService();
function Config() {
	const textColor = useColorModeValue("gray.700", "white");
	const [settings, setSettings] = useState([]);
	const [setting, setSetting] = useState({});
	const [isVisible, setIsVisible] = useState(false);
	const { setLoading, setLoadingText } = useContext(AppContext)

	const getSettings = useCallback(async () => {
		try {
			setLoadingText('Loading Settings');
			setLoading(true);
			const response = await storeService.getSettings();
			setSettings(response.data);
			setLoading(false);
		} catch (e) {
			message.error("Something went wrong")
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		getSettings();
	}, []);

	const onCloseModal = () => {
		setSetting({});
		getSettings();
		setIsVisible(false)
	}

	const editSetting = setting => setSetting(setting);

	useEffect(() => {
		if (setting.id) setIsVisible(true);
	}, [setting])

	return (
		<Flex direction="column" pt={{ base: "120px", md: "75px" }}>
			{isVisible && <AddSettingModal setting={setting} isVisible={isVisible} onClose={onCloseModal} />}
			<Card overflowX={{ sm: "scroll", xl: "hidden" }}>
				<CardHeader p="6px 0px 22px 0px">
					<Flex justifyContent="space-between" width="100%">
						<Text fontSize="xl" color={textColor} fontWeight="bold">
							Settings
						</Text>
						<Button type="primary" onClick={() => setIsVisible(true)}>Create Setting</Button>
					</Flex>
				</CardHeader>
				<CardBody>
					<SettingsTable editSetting={editSetting} settings={settings} />
				</CardBody>
			</Card>

		</Flex>
	);
}

export default Config;

import React from "react";
import {
    Table,
    Tbody,
    Th,
    Thead,
    Td,
    useColorModeValue,
    Tr,
} from "@chakra-ui/react";
import moment from "moment";

function StoreCategoriesTable({ categories, editCategory = () => {} }) {
	const textColor = useColorModeValue("gray.700", "white");
    return (
        <Table variant="simple" color={textColor}>
            <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400">
                    <Th pl="0px" color="gray.400">Name</Th>
                    <Th color="gray.400">Updated</Th>
                    <Th color="gray.400">Created</Th>
                </Tr>
            </Thead>
            <Tbody>
                {(categories || []).map((category) => {
                    return (
                        <Tr style={{ cursor: 'pointer' }} onClick={() => editCategory(category)} key={category.id}>

                            <Td>{category.name}</Td>
                            <Td>{moment(category.dateUpdated).format("DD/MM/YYYY HH:MM")}</Td>
                            <Td>{moment(category.dateCreated).format("DD/MM/YYYY HH:MM")}</Td>
                        </Tr>
                    );
                })}
            </Tbody>
        </Table>
    );
}

export default StoreCategoriesTable;

export const TOKEN_KEY = 'grubx_auth_token';

export const login = (token) => {
    localStorage.setItem(TOKEN_KEY, token);
}

export const logout = () => {
    localStorage.removeItem(TOKEN_KEY);
    window.location.reload();
}

export const isLogged = () => {
    return localStorage.getItem(TOKEN_KEY) !== null;
}

export const displayCADMoney = price => `${parseFloat(price / 100).toFixed(2)}$`;

export const isLocal = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" || window.location.hostname === "192.168.0.6";

const isProd = window.location.hostname === "admin.grubx.io";

export const ORDER_BASE_URL = isProd ? "https://order.grubx.io" : "https://order-dev.grubx.io";
export const BASE_URL = isProd ? "https://api.grubx.io" : (isLocal ? "http://localhost:3000" : "https://api.dev.grubx.io");

export const OrderStatusText = {
    PENDING: 'PENDING',
    READY_FOR_DRIVER: 'READY_FOR_DRIVER',
    READY_FOR_PICKUP: 'READY_FOR_PICKUP',
    IN_PROGRESS: 'IN_PROGRESS',
    ON_ROUTE_TO_STORE: 'ON_ROUTE_TO_STORE',
    ON_ROUTE_TO_CUSTOMER: 'ON_ROUTE_TO_CUSTOMER',
    DRIVER_IS_ON_CUSTOMER_ADDRESS: 'DRIVER_IS_ON_CUSTOMER_ADDRESS',
    COMPLETED: 'COMPLETED',
    PAID: 'PAID',
    VOIDED: 'VOIDED'
}

export const RefundedStatusText = {
    PENDING:'PENDING',
    FULL:'FULL',
    NO_REFUND:'NO_REFUND',
    PARTIALLY:'PARTIALLY'
}

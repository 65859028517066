import {
	Avatar,
	Badge,
	Button,
	Flex,
	Td,
	Text,
	Tr,
	useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import moment from 'moment';
import { Tag } from 'antd'
import { useHistory } from "react-router";

const StoreStatus = {
	AWAITING_APPROVAL: <Tag color="yellow">Awaiting approval</Tag>,
	OPENED: <Tag color="green">Opened</Tag>,
	CLOSED: <Tag color="#f50">Closed</Tag>,
	INACTIVE: <Tag color="geekblue">Inactive</Tag>,
}
function StoreTableRow({ store }) {
	const history = useHistory();
	const textColor = useColorModeValue("gray.700", "white");

	const goToStoreInfo = () => {
		history.push(`/admin/stores/${store.id}`);
	}

	return (
		<Tr style={{cursor: 'pointer'}} onClick={goToStoreInfo}>
			<Td minWidth={{ sm: "250px" }} pl="0px">
				<Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
					<Avatar src={store.logoUrl} w="50px" borderRadius="12px" me="18px" />
					<Text
						fontSize="md"
						color={textColor}
						fontWeight="bold"
						minWidth="100%"
					>
						{store.name}
					</Text>
				</Flex>
			</Td>
			<Td>{StoreStatus[store.status]}</Td>
			<Td><Tag color={store.createdBy === "GRUBX" ? "#3DB44D" : "geekblue"}>{store.createdBy}</Tag></Td>
			<Td>{store.homeStore && <Tag color="green">YES</Tag>}</Td>
			<Td>
				<Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
					{moment(store.dateCreated).format("DD/MM/YYYY")}
				</Text>
			</Td>
		</Tr>
	);
}
export default StoreTableRow;

import {
	Table,
	Tbody,
	Th,
	Thead,
	Td,
	Tr,
	Grid,
	GridItem
} from "@chakra-ui/react";
import { Divider, Modal, message, Select, Descriptions, Button, Tag } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons'
import moment from 'moment';
import { ButtonsContainer } from "./styled";
import { useEffect, useState } from "react";
import { Loader } from "components/Loader";
import OrderStatus from "components/OrderStatus";
import RefundedStatus from "components/RefundedStatus";
import OrderService from 'services/order.service';
import { displayCADMoney } from "helpers/utils";
import OrderType from "components/OrderType";
import CancelOrderModal from "components/CancelOrderModal";
import { OrderStatusText } from "helpers/utils";
import MapModal from "components/MapModal";
const orderService = new OrderService();
const { Option } = Select;

const OrderInformation = ({ order = {}, onOrderUpdated }) => {
	const [currentOrder, setCurrentOrder] = useState(order);
	const { user = {}, driver = {}, cart = {} } = order;
	const [orderStatus, setOrderStatus] = useState('');
	const [loading, setLoading] = useState(false);
	const [isVisible, setIsVisible] = useState(false);
	const [mapModalVisible, setMapModalVisible] = useState(false);

	const updateOrderStatus = status => {
		if (!status) return;
		Modal.confirm({
			title: 'Confirm',
			icon: <ExclamationCircleOutlined />,
			content: <div>Are you sure to change the order status to: {OrderStatus[status]}?</div>,
			okText: 'Update',
			cancelText: 'Cancel',
			onOk: async () => {
				try {
					const { data: updatedOrder } = await orderService.updateOrder(currentOrder.id, status);
					setCurrentOrder(updatedOrder);
					onOrderUpdated(updatedOrder);
					setLoading(false);
				} catch (e) {
					setLoading(false);
					message.error("Error updating order");
				}
			}
		});
	}

	useEffect(() => {
		setCurrentOrder(order)
	}, [order])

	return (
		<div>
			{loading && <Loader text={'Updating order status...'} />}
			<CancelOrderModal isVisible={isVisible} order={currentOrder} onClosed={() => setIsVisible(false)} />
			<MapModal driverId={(currentOrder.driver || {}).id} driverName={(currentOrder.driver || {}).firstName} onClose={() => setMapModalVisible(false)} isVisible={mapModalVisible}/>
			<Descriptions>
				<Descriptions.Item label="Created">
					<p>{moment.utc(currentOrder.dateCreated).local().format("DD/MM/YY HH:MM")}</p>
				</Descriptions.Item>
				<Descriptions.Item label="Customer">
					<p>{user ? `${user.firstName} ${user.lastName}` : currentOrder.userName}</p>
				</Descriptions.Item>
			</Descriptions>
			<Descriptions>
				<Descriptions.Item label="Type">
					<p>{OrderType[currentOrder.type]}</p>
				</Descriptions.Item>
				<Descriptions.Item label="Status">
					<p>{OrderStatus[currentOrder.status]}</p>
				</Descriptions.Item>
			</Descriptions>
			<Descriptions>
				<Descriptions.Item label="Payment method">
					<p>{currentOrder.stripeTransactionId === "CASH" ? <Tag color="lime">Cash</Tag> : <Tag color="#5469d4">Stripe</Tag>}</p>
				</Descriptions.Item>
			</Descriptions>
			<Descriptions>
				<Descriptions.Item>
					<Button type="text" danger onClick={() => setIsVisible(true)}>Refund order</Button>
				</Descriptions.Item>
			</Descriptions>
			{currentOrder.refundedStatus && (
				<Descriptions title="Refund information" bordered layout="vertical">
					<Descriptions.Item label="Refund Reason">
						<p>{order.refundReason}</p>
					</Descriptions.Item>
					<Descriptions.Item label="Total refunded">
						<p>{(parseFloat(currentOrder.totalRefunded) / 100).toFixed(2)}$</p>
					</Descriptions.Item>
					<Descriptions.Item label="Refunded">
						<p>{RefundedStatus[currentOrder.refundedStatus]}</p>
					</Descriptions.Item>
				</Descriptions>
			)
			}
			{driver && (
				<Descriptions style={{ marginBottom: 20 }} bordered column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }} layout="vertical" title="Driver">
					<Descriptions.Item label="Name">
						<p>{driver ? `${driver.firstName} ${driver.lastName}` : 'Unassigned'}</p>
					</Descriptions.Item>
					<Descriptions.Item label="Vehicle">
						<p>{driver && driver.driverInformation ? `${driver.driverInformation.vehicle}` : 'Unassigned'}</p>
					</Descriptions.Item>
					<Descriptions.Item label="Location">
						<Button onClick={() => setMapModalVisible(true)} type="link">See in realtime</Button>
					</Descriptions.Item>
				</Descriptions>
			)}
			{currentOrder.store && (
				<Descriptions style={{ marginBottom: 20 }} bordered column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }} layout="vertical" title="Store">
					<Descriptions.Item label="Name">
						<p><a target="_blank" href={'/admin/stores/' + currentOrder.store.id}>{currentOrder.store.name}</a></p>
					</Descriptions.Item>
					<Descriptions.Item label="Order minimum">
						<p>{parseFloat(currentOrder.store.orderMinimum / 100).toFixed(2)}$</p>
					</Descriptions.Item>
				</Descriptions>
			)}
			{currentOrder.location && (
				<>
					<Descriptions bordered column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }} layout="vertical" title="Store Location">
						<Descriptions.Item label="Name">
							<p>{currentOrder.location.name}</p>
						</Descriptions.Item>
						<Descriptions.Item label="Address">
							<p>{currentOrder.location.address}</p>
						</Descriptions.Item>
						<Descriptions.Item label="City">
							<p>{currentOrder.location.city}</p>
						</Descriptions.Item>
					</Descriptions>
					<Descriptions bordered column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }} layout="vertical">
						<Descriptions.Item label="Latitude">
							<p>{currentOrder.location.latitude}</p>
						</Descriptions.Item>
						<Descriptions.Item label="Longitude">
							<p>{currentOrder.location.longitude}</p>
						</Descriptions.Item>
					</Descriptions>
				</>
			)}
			{currentOrder.address && (
				<>
					<br/>
					<Descriptions bordered column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }} layout="vertical" title="Customer Address">
						<Descriptions.Item label="Name">
							<p>{currentOrder.address.name}</p>
						</Descriptions.Item>
						<Descriptions.Item label="Address">
							<p>{currentOrder.address.address}</p>
						</Descriptions.Item>
						<Descriptions.Item label="City">
							<p>{currentOrder.address.city}</p>
						</Descriptions.Item>
					</Descriptions>
					<Descriptions bordered column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }} layout="vertical">
						<Descriptions.Item label="Latitude">
							<p>{currentOrder.address.latitude}</p>
						</Descriptions.Item>
						<Descriptions.Item label="Longitude">
							<p>{currentOrder.address.longitude}</p>
						</Descriptions.Item>
					</Descriptions>
				</>
			)}
			{currentOrder.status !== 'COMPLETED' && currentOrder.status !== 'VOIDED' && <Divider />}
			{currentOrder.status !== 'COMPLETED' && currentOrder.status !== 'VOIDED' && <div className="ant-descriptions-title">Update order status</div>}
			{currentOrder.status === 'PAID' && <Button onClick={() => updateOrderStatus('IN_PROGRESS')} color="primary">Start order preparation</Button>}
			{(currentOrder.status === 'READY_FOR_PICKUP' || currentOrder.status === 'READY_FOR_DRIVER') && <Button onClick={() => updateOrderStatus('COMPLETED')} color="primary">Order completed</Button>}

			{['IN_PROGRESS'].indexOf(currentOrder.status) !== -1 && <ButtonsContainer>
				<Select type="select" style={{ width: 120 }} value={orderStatus} onChange={setOrderStatus}>
					<Option value=''>-- Pick one --</Option>
					{
						currentOrder.type === 'DELIVERY' ? <Option value="READY_FOR_DRIVER">Ready for driver</Option> : <Option value="READY_FOR_PICKUP">Ready for pickup</Option>
					}
					<Option value="REFUNDED">Refunded</Option>
					<Option value="PARTIALLY">Partially refunded</Option>
					<Option value="VOIDED">Voided</Option>
				</Select>
				<Button color="primary" onClick={() => updateOrderStatus(orderStatus)}>Update</Button>
			</ButtonsContainer>}
			<Divider />
			<div className="ant-descriptions-title">Cart items</div>
			<Table>
				<Thead>
					<Tr>
						<Th>Product</Th>
						<Th>Quantity</Th>
						<Th>Total</Th>
					</Tr>
				</Thead>
				<Tbody>
					{
						(cart.cartItems || []).map(cartItem =>
							<Tr key={cartItem.id}>
								<Td>{cartItem.product.name}</Td>
								<Td>{cartItem.quantity}</Td>
								<Td>{(parseFloat(cartItem.total) / 100).toFixed(2)}$</Td>
							</Tr>
						)
					}
					<Tr style={{ backgroundColor: 'aliceblue' }}>
						<Td><b>SubTotal</b></Td>
						<Td></Td>
						<Td>{displayCADMoney(cart.subTotal)}</Td>
					</Tr>
					<Tr style={{ backgroundColor: 'ghostwhite' }}>
						<Td><b>GrubX Fee</b></Td>
						<Td></Td>
						<Td>{displayCADMoney(cart.grubxFee)}</Td>
					</Tr>
					<Tr style={{ backgroundColor: 'aliceblue' }}>
						<Td><b>Taxes</b></Td>
						<Td></Td>
						<Td>{displayCADMoney(cart.taxAmount)}</Td>
					</Tr>
					<Tr style={{ backgroundColor: 'ghostwhite' }}>
						<Td style={{ fontSize: 20 }}><b>Total</b></Td>
						<Td></Td>
						<Td style={{ fontSize: 20 }}>{displayCADMoney(order.total)}</Td>
					</Tr>
				</Tbody>
			</Table>
		</div>
	)
}
export default OrderInformation;
// import
import shopIcon from 'assets/img/store.svg'
import settingsIcon from 'assets/img/settings.png'
import driverIcon from 'assets/img/driver.svg'
import customerIcon from 'assets/img/customer.svg'
import searchIcon from 'assets/img/search.png'
import chatIcon from 'assets/img/chat.png'
import cashIcon from 'assets/img/cashback.png'
import categoriesIcon from 'assets/img/options.png'
import Stores from "views/Stores";
import StoreInfo from "views/Stores/StoreInfo";
import SignIn from "views/Pages/SignIn";
import Drivers from "views/Drivers";
import DriverInformation from "views/Drivers/DriverInformation";
import Customers from "views/Customers";
import CustomerInfo from "views/Customers/CustomerInfo";
import Users from "views/Users";
import Chats from "views/Chats";
import Refunds from "views/Refunds";
import StoreCategories from "views/StoreCategories";
import Config from "views/Config";
import SearchOrder from 'views/SearchOrder'

var dashRoutes = [
  {
    path: "/stores",
    name: "Stores",
    icon: <img src={shopIcon} style={{ width: '21px', maxHeight: 'unset', height: 'auto' }} />,
    component: Stores,
    layout: "/admin"
  },
  {
    path: "/stores/:storeId",
    name: "Stores",
    component: StoreInfo,
    noshow: true,
    layout: "/admin",
  },
  {
    path: "/signin",
    name: "Login",
    component: SignIn,
    noshow: true,
    layout: "/auth",
  },
  {
    path: "/drivers",
    name: "Drivers",
    icon: <img src={driverIcon} style={{ width: '21px', maxHeight: 'unset', height: 'auto' }} />,
    component: Drivers,
    layout: "/admin",
  },
  {
    path: "/drivers/:driverId",
    name: "Driver Info",
    noshow: true,
    icon: <img src={driverIcon} style={{ width: '21px', maxHeight: 'unset', height: 'auto' }} />,
    component: DriverInformation,
    layout: "/admin",
  },
  {
    path: "/customers",
    name: "Customers",
    icon: <img src={customerIcon} style={{ width: '21px', maxHeight: 'unset', height: 'auto' }} />,
    component: Customers,
    layout: "/admin",
  },
  {
    path: "/customers/:userId",
    name: "Customer Info",
    noshow: true,
    icon: <img src={customerIcon} style={{ width: '21px', maxHeight: 'unset', height: 'auto' }} />,
    component: CustomerInfo,
    layout: "/admin",
  },
  {
    path: "/users",
    name: "Users",
    icon: <img src={customerIcon} style={{ width: '21px', maxHeight: 'unset', height: 'auto' }} />,
    component: Users,
    layout: "/admin",
  },
  {
    path: "/chats",
    name: "Chats",
    icon: <img src={chatIcon} style={{ width: '21px', maxHeight: 'unset', height: 'auto' }} />,
    component: Chats,
    layout: "/admin",
  },
  {
    path: "/refunds",
    name: "Refunds",
    icon: <img src={cashIcon} style={{ width: '21px', maxHeight: 'unset', height: 'auto' }} />,
    component: Refunds,
    layout: "/admin",
  },
  {
    path: "/store-categories",
    name: "Categories",
    icon: <img src={categoriesIcon} style={{ width: '21px', maxHeight: 'unset', height: 'auto' }} />,
    component: StoreCategories,
    layout: "/admin",
  },
  {
    path: "/settings",
    name: "Settings",
    icon: <img src={settingsIcon} style={{ width: '21px', maxHeight: 'unset', height: 'auto' }} />,
    component: Config,
    layout: "/admin",
  },
  {
    path: "/search-order",
    name: "Search Order",
    icon: <img src={searchIcon} style={{ width: '21px', maxHeight: 'unset', height: 'auto' }} />,
    component: SearchOrder,
    layout: "/admin",
  },
];
export default dashRoutes;

import React, { useCallback, useContext, useEffect, useState } from "react";
import {
	Flex,
	Table,
	Tbody,
	Text,
	Th,
    Td,
	Thead,
	Tr,
	useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { AppContext } from "AppContext";
import { message } from "antd";
import UserService from "services/user.service";
import moment from "moment";
import { useHistory } from "react-router";
const userService = new UserService();
function Customers() {
	const textColor = useColorModeValue("gray.700", "white");
	const [customers, setCustomers] = useState([]);
	const { setLoading } = useContext(AppContext);
	const history = useHistory();

	const getCustomers = useCallback(async () => {
		try {
			setLoading(true);
			const drivers = await userService.getUsersByRole("CUSTOMER");
			setCustomers(drivers);
			setLoading(false);
		} catch(e) {
			message.error("Something went wrong")
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		getCustomers();
	}, []);

	const onClickDriverRow = customer => {
		history.push(`/admin/customers/${customer.id}`);
	}

	return (
		<Flex direction="column" pt={{ base: "120px", md: "75px" }}>
			<Card overflowX={{ sm: "scroll", xl: "hidden" }}>
				<CardHeader p="6px 0px 22px 0px">
					<Text fontSize="xl" color={textColor} fontWeight="bold">
						Customers
					</Text>
				</CardHeader>
				<CardBody>
					<Table variant="simple" color={textColor}>
						<Thead>
							<Tr my=".8rem" pl="0px" color="gray.400">
								<Th pl="0px" color="gray.400">Name</Th>
								<Th color="gray.400">Email</Th>
								<Th color="gray.400">Phone</Th>
								<Th color="gray.400">Created</Th>
								<Th></Th>
							</Tr>
						</Thead>
						<Tbody>
							{(customers || []).map((customer) => {
								return (
									<Tr style={{cursor: 'pointer'}} onClick={() => onClickDriverRow(customer)} key={customer.id}>
                                        <Td>{customer.firstName} {customer.lastName}</Td>
                                        <Td>{customer.email}</Td>
                                        <Td>+{customer.countryCode} {customer.phone}</Td>
                                        <Td>{moment(customer.dateCreated).format("DD/MM/YYYY HH:MM")}</Td>
                                    </Tr>
								);
							})}
						</Tbody>
					</Table>
				</CardBody>
			</Card>
			
		</Flex>
	);
}

export default Customers;

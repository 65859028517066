import React, { useCallback, useEffect, useState } from "react";
import { Loader } from "components/Loader";
import { Select, Empty, DatePicker, Space, Input } from "antd";
import { ButtonsContainer } from "views/Orders/styled";
import { CustomDrawer } from "views/Orders/styled";
import OrderInformation from "views/Orders/OrderInformation";
import OrderService from "services/order.service";
import OrdersTable from "views/Orders/OrdersTable";
const { RangePicker } = DatePicker;
const { Option } = Select;
const orderService = new OrderService();

function OrdersTab({ storeId, customerId, driverId }) {
    const [orderNo, setOrderNo] = useState();
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState([]);
    const [order, setOrder] = useState({});
    const [rangeDates, setRangeDates] = useState([]);
    const [orderStatus, setOrderStatus] = useState('all');

    const onOrderOpened = order => {
        setOrder(order);
    }

    const onClose = () => {
        setOrder({});
    };

    const fetchOrders = useCallback(async () => {
        try {
            setLoading(true);
            let orders = [];
            if (storeId) {
                orders = await orderService.getStoreOrders(storeId, orderStatus, rangeDates[0], rangeDates[1]);
            } else if (customerId) {
                orders = await orderService.getCustomerOrders(customerId, orderStatus, rangeDates[0], rangeDates[1]);
            } else if (driverId) {
                orders = await orderService.getDriverOrders(driverId, orderStatus, rangeDates[0], rangeDates[1]);
            }
            setOrders(orders);
            setLoading(false);
        } catch (e) {
            setLoading(false);
            // message.error(e.response.data.error.message)
        }


    }, [orderStatus, rangeDates, storeId, customerId, driverId]);

    useEffect(() => {
        fetchOrders();
    }, [storeId, driverId, customerId, orderStatus, rangeDates, fetchOrders]);

    const onRangePickerChange = date => {
        setRangeDates((date || []).map(d => d.format('YYYY-MM-DD')));
    }

    return (
        <div>
            {loading && <Loader />}
            <ButtonsContainer>
                <Select type="select" style={{ width: 120 }} value={orderStatus} onChange={setOrderStatus}>
                    <Option value="all">All orders</Option>
                    <Option value="PENDING">Pending</Option>
                    <Option value="READY_FOR_DRIVER">Ready for driver</Option>
                    <Option value="READY_FOR_PICKUP">Ready for pickup</Option>
                    <Option value="IN_PROGRESS">In progress</Option>
                    <Option value="ON_ROUTE">On route</Option>
                    <Option value="COMPLETED">Completed</Option>
                    <Option value="PAID">Paid</Option>
                </Select>
                <Space style={{ marginLeft: 20 }} size={12}>
                    <RangePicker onChange={onRangePickerChange} />
                </Space>
                <Input style={{ marginLeft: 20, maxWidth: 100 }} onChange={e => setOrderNo(e.target.value)} value={orderNo} placeholder="Filter by order number" />
            </ButtonsContainer>
            {orders.length > 0 ? <OrdersTable onOrderOpened={onOrderOpened} orders={orders.filter(o => orderNo ? o.orderNo == orderNo : true)} /> : <Empty style={{ marginBottom: 20 }} />}
            <CustomDrawer
                title={`Order #${(order || {}).orderNo}`}
                width={520}
                placement="right"
                onClose={onClose}
                visible={order.id !== undefined}
            >
                <OrderInformation onOrderUpdated={fetchOrders} order={order} />
            </CustomDrawer>
        </div>
    );
}

export default OrdersTab;

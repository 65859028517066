import { Button, Checkbox, Divider, Input, Select, message, Modal, Form, Result } from "antd";
const { Option } = Select;
import { Loader } from "components/Loader";
import { useEffect, useState } from "react";
import StoreService from "services/store.service";
const storeService = new StoreService();
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};
const CreateCategory = ({ isVisible, category = {}, onClose }) => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [form] = Form.useForm();

    const createCategory = async ({ category: formData }) => {
        try {
            let newCategory = {};
            setLoading(true);
            if (category.id) {
                newCategory = await storeService.updateCategory(category.id, formData.name);

            } else {
                newCategory = await storeService.createCategory({
                    ...formData,
                });
            }
            setSuccess(true);
            setLoading(false);
        } catch (e) {
            setLoading(false);
            message.error("Something was wrong creating the category, please try again in a while")
        }
    }

    const onCancel = () => {
        form.resetFields();
        setSuccess(false);
        onClose();
    }

    return (
        <Modal title={category.id ? 'Update category' : 'Create new category'} onCancel={onCancel} footer={null} visible={isVisible}>
            {loading && <Loader text={category.id ? "Updating category" : "Creating category"} />}
            {
                success ? (
                    <Result
                        status="success"
                        title={`Successfully category ${category.id ? 'updated' : 'created'}!`}
                    />
                ) : (
                    <>
                        <Form form={form} initialValues={{ category: category.id ? { ...category } : {} }} {...layout} name="nest-messages" onFinish={createCategory} validateMessages={validateMessages}>
                            <Form.Item name={['category', 'name']} label="Category name" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                                <Button type="primary" htmlType="submit">
                                    {category.id ? 'Update' : 'Create'}
                                </Button>
                            </Form.Item>
                        </Form>
                    </>
                )
            }
        </Modal>
    )
}

export default CreateCategory;
import React, { useEffect, useState } from 'react';
import { Input, Button, List } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { ProductExtrasContainer } from './styled';
const ExtrasForm = ({ productExtras, onUpdate}) => {
	const [extraPrice, setExtraPrice] = useState('');
	const [extraName, setExtraName] = useState('');
	const [available, setAvaliable] = useState(true);
	const [extras, setExtras] = useState(productExtras || []);
	const [, setCounter] = useState(0);

	const addExtra = () => {
		if (extraPrice < 0 || isNaN(extraPrice) || !extraName) return;
		setExtras([...extras, {
			name: extraName,
			price: parseInt(extraPrice * 100),
			available
		}]);
		setExtraName('');
		setExtraPrice('');
		setAvaliable(true);
		setCounter(prev => prev = prev + 1);
	}

	useEffect(() => {
		onUpdate(extras)
	}, [extras, onUpdate])

	const removeExtra = (e, index) => {
		e.preventDefault();
		setExtras(prev => {
			prev.splice(index, 1);
			return prev;
		})
		setCounter(prev => prev = prev + 1);
	}

	return (
		<ProductExtrasContainer>
			<p>Product extras</p>
			<div className="form-container-extras">
					<Input
						type="text"
						placeholder="name"
						value={extraName}
						onChange={e => setExtraName(e.target.value)}
						style={{ width: 100 }}
					/>
					<Input
						type="number"
						min={0}
						placeholder="price"
						value={extraPrice}
						onChange={e => setExtraPrice(e.target.value)}
						style={{ width: 100 }}
					/>
					<Checkbox checked={available} onChange={() => setAvaliable(!available)}>Available</Checkbox>
					<Button onClick={addExtra} type="primary">
						Add
					</Button>
			</div>
			<List
				className="demo-loadmore-list"
				itemLayout="horizontal"
				dataSource={extras}
				renderItem={(item, index) => (
					<List.Item
						actions={[<a onClick={(e) => removeExtra(e, index)} style={{color: 'red'}} href="/#" key="list-loadmore-edit">remove</a>]}
					>
						<>
							<List.Item.Meta
								title={item.name}
								description={parseFloat(item.price / 100).toFixed(2)}
							/>
							<div><Checkbox checked={item.available}>Available</Checkbox></div>
						</>
					</List.Item>
				)}
			/>
		</ProductExtrasContainer>
	);
};

export default ExtrasForm;
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
    Flex,
    Table,
    Tbody,
    Text,
    Th,
    Td,
    Thead,
    Box,
    Tr,
    useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { AppContext } from "AppContext";
import { message, Button, Select } from "antd";
import UserService from "services/user.service";
import moment from "moment";
import { useHistory } from "react-router";
import AddUserModal from "./AddUserModal";
const { Option } = Select;
const userService = new UserService();
function Users() {
    const textColor = useColorModeValue("gray.700", "white");
    const [users, setUsers] = useState([]);
    const [role, setRole] = useState("ADMIN");
    const { setLoading } = useContext(AppContext);
    const [isVisible, setIsVisible] = useState(false);
    const history = useHistory();

    const getCustomers = useCallback(async () => {
        try {
            setLoading(true);
            const _users = await userService.getUsersByRole(role);
            setUsers(_users);
            setLoading(false);
        } catch (e) {
            message.error("Something went wrong")
            setLoading(false);
        }
    }, [role]);

    useEffect(() => {
        getCustomers();
    }, [role]);

    const onClickDriverRow = user => {
        history.push(`/admin/users/${user.id}`);
    }

    const onChangeSelect = value => {
        setRole(value);
    }

    const onCloseModal = () => {
        setIsVisible(false);
        getCustomers();
    }

    const copyToClipboard = (e, userId) => {
        e.stopPropagation()
        window.navigator.clipboard.writeText(userId).then(function() {
            message.success('UserId copied to clipboard');
          }, function(err) {
            message.error('Could not copy text: ');
          });
    }

    return (
        <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
            <AddUserModal isVisible={isVisible} onClose={onCloseModal} />
            <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
                <CardHeader p="6px 0px 22px 0px">
                    <Flex width="100%" justifyContent="space-between">
                    <Text fontSize="xl" color={textColor} fontWeight="bold">
                        Store and admin users
                    </Text>
                    <Button type="primary" onClick={() => setIsVisible(true)}>Create User</Button>
                    </Flex>
                </CardHeader>
                <CardBody>
                    <Box>
                    <Select onChange={onChangeSelect} value={role} style={{ width: 120 }}>
                        <Option value="ADMIN">Admin</Option>
                        <Option value="STORE_OWNER">Store owner</Option>
                        <Option value="STORE_EMPLOYEE">Store employee</Option>
                    </Select>
                    <Table variant="simple" color={textColor}>
                        <Thead>
                            <Tr my=".8rem" pl="0px" color="gray.400">
                                <Th pl="0px" color="gray.400">Name</Th>
                                <Th color="gray.400">Role</Th>
                                <Th color="gray.400">Email</Th>
                                <Th color="gray.400">Phone</Th>
                                <Th color="gray.400">Created</Th>
                                <Th>Actions</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {(users || []).map((user) => {
                                return (
                                    <Tr style={{ cursor: 'pointer' }} onClick={() => onClickDriverRow(user)} key={user.id}>
                                        <Td>{user.firstName} {user.lastName}</Td>
                                        <Td>{user.role}</Td>
                                        <Td>{user.email}</Td>
                                        <Td>{user.countryCode} {user.phone}</Td>
                                        <Td>{moment(user.dateCreated).format("DD/MM/YYYY HH:MM")}</Td>
                                        <Td>
                                            <Button onClick={(e) => copyToClipboard(e, user.id)} type="link">Copy userId</Button>
                                        </Td>
                                    </Tr>
                                );
                            })}
                        </Tbody>
                    </Table>
                    </Box>
                </CardBody>
            </Card>

        </Flex>
    );
}

export default Users;

import { request } from "../helpers/request"
const BASE_URL = '/stores';
class StoreService {
    constructor() { }

    getAllStores = async () => {
        const { data } = await request.get(`${BASE_URL}/admin/all`);
        return data;
    }

    getOne = async storeId => {
        const { data } = await request.get(`${BASE_URL}/admin/${storeId}`);
        return data;
    }

    getSubs = async storeId => {
        const { data } = await request.get(`${BASE_URL}/admin/subs/${storeId}`);
        return data;
    }

    getProvinces = async () => {
        const { data } = await request.get(`/provinces`);
        return data;
    }

    createStore = async newStore => {
        const { data } = await request.post(`${BASE_URL}`, { ...newStore, createdBy: 'GRUBX' });
        return data;
    }

    updateStoreStatus = async (storeId, status) => {
        const { data } = await request.put(`${BASE_URL}/update-status-by-admin/${storeId}`, {
            status
        });
        return data;
    }

    updateStore = async (storeId, data) => {
        return request.put(`${BASE_URL}/${storeId}`, data);
    }

    addNewLocation = async (storeId, locationData) => {
        return request.post(`${BASE_URL}/${storeId}/location`, locationData);
    }

    deleteStoreLocation = async (storeId, locationId) => {
        return request.delete(`${BASE_URL}/${storeId}/location/${locationId}`);
    }

    cancelSubscription = async (storeId, subscriptionId) => {
        return request.delete(`${BASE_URL}/admin/subs/${storeId}/${subscriptionId}`);
    }

    deleteStore = async (storeId) => {
        return request.delete(`${BASE_URL}/${storeId}`);
    }

    payToStore = async (storeId, amount) => {
        return request.post(`${BASE_URL}/payout/${storeId}`, { amount });
    }

    addStoreLogo = (storeId, file) => {
        const formData = new FormData();
        formData.append("logo", file);
        return request.put(`${BASE_URL}/${storeId}/logo`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }

    getBankAccountInformation = async storeId => {
        const { data } = await request.get(`${BASE_URL}/account-information/${storeId}`);
        return data;
    }

    createCategory = (category) => {
        return request.post(`${BASE_URL}/category`, category)
    }

    getOneCategory = (categoryId) => {
        return request.get(`${BASE_URL}/category/${categoryId}`)
    }

    getCategories = () => {
        return request.get(`${BASE_URL}/categories`)
    }

    updateCategory = (categoryId, name) => {
        return request.put(`${BASE_URL}/category/${categoryId}`, {
            name
        })
    }

    deleteCategory = (categoryId) => {
        return request.delete(`${BASE_URL}/category/${categoryId}`);
    }

    addStoreToCategory = (storeId, categoryId) => {
        return request.put(`${BASE_URL}/${storeId}/category/${categoryId}`)
    }

    deleteStoreFromCategory = (storeId, categoryId) => {
        return request.delete(`${BASE_URL}/${storeId}/category/${categoryId}`)
    }

}


export default StoreService;
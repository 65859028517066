import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import 'antd/dist/antd.css';
import 'react-chat-elements/dist/main.css';
import { AppContextProvider } from "AppContext";

ReactDOM.render(
  <AppContextProvider>
    <BrowserRouter>
      <Switch>
        <Route path={`/auth`} component={AuthLayout} />
        <Route path={`/admin`} component={AdminLayout} />
        <Redirect from={`*`} to="/admin/stores" />
      </Switch>
    </BrowserRouter>
  </AppContextProvider>
  ,
  document.getElementById("root")
);

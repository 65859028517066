import { Button, Checkbox, Input, Select, message, Modal, Form, Result } from "antd";
const { Option } = Select;
import { Loader } from "components/Loader";
import _ from "lodash";
import { useEffect, useState, useCallback } from "react";
import StoreService from "services/store.service";
const storeService = new StoreService();

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};
const CreateStore = ({ isVisible, store = {}, onClose }) => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [storeLogo, setStoreLogo] = useState(null);
    const [categories, setCategories] = useState([]);
    const [form] = Form.useForm();

    const getCategories = useCallback(async () => {
        try {
            setLoading(true);
            const response = await storeService.getCategories();
            setCategories(response.data);
            setLoading(false);
        } catch (e) {
            message.error("Something went wrong")
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        getCategories();
    }, [])


    useEffect(() => {
        setStoreLogo(null)
        if (store) {
            form.setFieldsValue({
                categories: (store.storeCategories || []).map((c) => c.id),
                store: {
                    ...store,
                    orderMinimum: parseFloat(store.orderMinimum / 100).toFixed(2)
                }
            });
        } else {
            form.resetFields();
        }
    }, [store, form]);

    const createStore = async ({ store: formData, categories: form_categories = [] }) => {
        let newStore = {};
        try {
            setLoading(true);
            if (store.id) {
                newStore = await storeService.updateStore(store.id, {
                    ...formData,
                    orderMinimum: parseInt(parseFloat(formData.orderMinimum).toFixed(2) * 100)
                });

            } else {

                newStore = await storeService.createStore({
                    ...formData,
                    orderMinimum: parseInt(parseFloat(formData.orderMinimum).toFixed(2) * 100)
                });
            }
            if (store.id && form_categories.length > 0) {
                const deletedCategories = _.difference(store.storeCategories.map((c) => c.id), form_categories);
                const newCategories = _.difference(form_categories, store.storeCategories.map((c) => c.id));
                for (const categoryId of deletedCategories) {
                    await storeService.deleteStoreFromCategory(store.id, categoryId);
                }
                for (const categoryId of newCategories) {
                    await storeService.addStoreToCategory(store.id, categoryId);
                }

            } else if (!store.id && form_categories.length > 0) {
                for (const category of form_categories) {
                    await storeService.addStoreToCategory(newStore.id, category);
                }
            } else if (store.id && form_categories.length === 0 && store.storeCategories.length) {
                for (const category of store.storeCategories) {
                    await storeService.deleteStoreFromCategory(store.id, category.id);
                }
            }



            setSuccess(true);
            setStoreLogo(null);
            setLoading(false);
        } catch (e) {
            console.log(e)
            setLoading(false);
            message.error("Something was wrong creating the store, please try again in a while")
        }


        if (storeLogo && (newStore.id || store.id)) {
            try {
                await storeService.addStoreLogo(newStore.id || store.id, storeLogo)
            } catch (e) {

            }
        }
    }

    const onCancel = () => {
        setSuccess(false);
        onClose();
    }
    const onUploadLogo = (e) => {
        setStoreLogo(e.target.files[0])
    }

    return (
        <Modal title={store.id ? 'Update store' : 'Create new store'} onCancel={onCancel} footer={null} visible={isVisible}>
            {loading && <Loader text={store.id ? "Updating store" : "Creating store"} />}
            {
                success ? (
                    <Result
                        status="success"
                        title={`Successfully store ${store.id ? 'updated' : 'created'}!`}
                        subTitle={store.id ? 'Please check your new changes' : `Please login on store portal and activate it`}
                    />
                ) : (
                    <>
                        <Form form={form} initialValues={{ pickupAvailable: false, prefix: '1', deliveryAvailable: false }} {...layout} name="nest-messages" onFinish={createStore} validateMessages={validateMessages}>
                            <Form.Item name={['store', 'name']} label="Business name" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            {store.id && <Form.Item name={['store', 'slug']} label="Slug" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>}
                            <Form.Item name={['store', 'orderMinimum']} label="Order Minimum" rules={[{ required: true }]}>
                                <Input type='number' />
                            </Form.Item>
                            <Form.Item name={['store', 'ownerId']} label="Owner user id" rules={[{ required: false }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name={['store', "pickupAvailable"]} valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
                                <Checkbox>Pick up availabe</Checkbox>
                            </Form.Item>
                            <Form.Item name={['store', "deliveryAvailable"]} valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
                                <Checkbox>Delivery availabe</Checkbox>
                            </Form.Item>
                            <Form.Item name={['store', "homeStore"]} valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
                                <Checkbox>Home Store</Checkbox>
                            </Form.Item>
                            <Form.Item
                                name="categories"
                                label="Categories"
                            >
                                <Select mode="multiple" placeholder="Select one or more categories">
                                    {
                                        categories.map((category) => <Option key={category.id} value={category.id}>{category.name}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item label="Logo" rules={[{}]}>
                                <Input onChange={onUploadLogo} type="file" />
                            </Form.Item>

                            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                                <Button type="primary" htmlType="submit">
                                    {store.id ? 'Update' : 'Create'}
                                </Button>
                            </Form.Item>
                        </Form>
                    </>
                )
            }
        </Modal>
    )
}

export default CreateStore;
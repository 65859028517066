import React, { useCallback, useContext, useEffect, useState } from "react";
import {
	Flex,
	Table,
	Tbody,
	Text,
	Th,
    Td,
	Thead,
	Tr,
	useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { AppContext } from "AppContext";
import { message, Tag } from "antd";
import UserService from "services/user.service";
import moment from "moment";
import { useHistory } from "react-router";
const userService = new UserService();
const DRIVER_STATUS = {
    APPROVED: <Tag color="green">APPROVED</Tag>,
    REJECTED: <Tag color="red">REJECTED</Tag>,
    PENDING: <Tag color="blue">PENDING</Tag>,
}
function Drivers() {
	const textColor = useColorModeValue("gray.700", "white");
	const [drivers, setDrivers] = useState([]);
	const { setLoading, setLoadingText, setCurrentDriver } = useContext(AppContext);
	const history = useHistory();

	const getDrivers = useCallback(async () => {
		try {
			setLoadingText('Loading Drivers');
			setLoading(true);
			const drivers = await userService.getUsersByRole("DRIVER");
			setDrivers(drivers);
			setLoading(false);
		} catch(e) {
			message.error("Something went wrong")
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		getDrivers();
	}, []);

	const onClickDriverRow = driver => {
		setCurrentDriver(driver);
		history.push(`/admin/drivers/${driver.id}`);
	}

	return (
		<Flex direction="column" pt={{ base: "120px", md: "75px" }}>
			<Card overflowX={{ sm: "scroll", xl: "hidden" }}>
				<CardHeader p="6px 0px 22px 0px">
					<Text fontSize="xl" color={textColor} fontWeight="bold">
						Drivers
					</Text>
				</CardHeader>
				<CardBody>
					<Table variant="simple" color={textColor}>
						<Thead>
							<Tr my=".8rem" pl="0px" color="gray.400">
								<Th pl="0px" color="gray.400">Name</Th>
								<Th color="gray.400">Email</Th>
								<Th color="gray.400">Phone</Th>
								<Th color="gray.400">Status</Th>
								<Th color="gray.400">Created</Th>
								<Th></Th>
							</Tr>
						</Thead>
						<Tbody>
							{(drivers || []).map((driver) => {
								return (
									<Tr style={{cursor: 'pointer'}} onClick={() => onClickDriverRow(driver)} key={driver.id}>
                                        <Td>{driver.firstName} {driver.lastName}</Td>
                                        <Td>{driver.email}</Td>
                                        <Td>+{driver.countryCode} {driver.phone}</Td>
                                        <Td>{driver.driverInformation ? DRIVER_STATUS[driver.driverInformation.status] : 'No information submitted'}</Td>
                                        <Td>{moment(driver.dateCreated).format("DD/MM/YYYY HH:MM")}</Td>
                                    </Tr>
								);
							})}
						</Tbody>
					</Table>
				</CardBody>
			</Card>
			
		</Flex>
	);
}

export default Drivers;

import { Modal } from "antd";
import { useEffect, useState } from "react";
import GoogleMapReact from 'google-map-react';
import RealtimeLocationService from "services/realtime-location-microservice";
import driverIcon from 'assets/img/driver.svg'
const realTimeService = new RealtimeLocationService();
const AnyReactComponent = () => <img src={driverIcon} style={{width: 50, height: 50}} />;
const TORONTO_COORDS = {"lat": 43.6584556629882, "lng": -79.38318438827991};

const MapModal = ({ isVisible, driverId, driverName, onClose }) => {
    const [location, setLocation] = useState({});

    useEffect(() => {
        const init = async () => {
            try {
                const { data } = await realTimeService.getDriverLocation(driverId);
                console.log(data.body.hits[0]._source)
                setLocation({
                    lat: data.body.hits[0]._source.latitude,
                    lng: data.body.hits[0]._source.longitude,
                });
            } catch(e) {
                console.log(e)
            }
            
        }
        const interval = setInterval(init, 5000);
        return () => clearInterval(interval);
    }, [driverId])



    return (
        <Modal width={900} bodyStyle={{ height: 500 }} title={`${driverName}'s location`} onCancel={onClose} footer={null} visible={isVisible}>

            <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyCmqIGqiTPgx9XvWIHZALppY4ApzUm5uk0' }}
                center={location}
                defaultCenter={TORONTO_COORDS}
                defaultZoom={11}
                onGoogleApiLoaded={({ map, maps }) => console.log(map, maps)}
            >
                <AnyReactComponent
                    lat={location.lat}
                    lng={location.lng}
                />
            </GoogleMapReact>

        </Modal>
    )
}

export default MapModal;